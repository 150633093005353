import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DownButton } from '../../../../../Components/Predict/actionButton/downButton'
import { UpButton } from '../../../../../Components/Predict/actionButton/upButton'
import { Card } from '../../../../../Components/Predict/card/card'
import MiniLoader from '../../../../../Components/Predict/loader/MiniLoader'
import GameContext from '../../../../../Context/GameContext'
import { useTheme } from '../../../../../Context/Predict/themeContext'
import { Game } from '../../../../../Domain/Predict/game'
import { GameProcess, GameProcessType } from '../../../../../Domain/Predict/gameProcess'
import CheckedIcon from '../../../../../Resources/Predict/icons/Checked.svg'
import CrossIcon from '../../../../../Resources/Predict/icons/cross.svg'
import DownFillArrowIcon from '../../../../../Resources/Predict/icons/downFullArrow.svg'
import DownFillArrowLightIcon from '../../../../../Resources/Predict/icons/downFullArrow_light.svg'
import linkIcon from '../../../../../Resources/Predict/icons/link2.svg'
import liveIcon from '../../../../../Resources/Predict/icons/live.svg'
import UpFillArrowIcon from '../../../../../Resources/Predict/icons/upFullArrow.svg'
import UpFillArrowLightIcon from '../../../../../Resources/Predict/icons/upFullArrowLight.svg'
import coinIcon from '../../../../../Resources/Predict/images/coin.svg'
import style from './prediction.module.scss'

interface IProps {
    gameProcess: GameProcess;
    game: Game;
    canSelect: boolean;
    onUp: () => void;
    onDown: () => void;
}



export function PredictionCard(props: IProps) {
    switch (props.gameProcess.type) {
        case GameProcessType.MoneyCollection: return MoneyCollectionCard(props);
        case GameProcessType.CurrentlyActive: return CurrentlyActiveCard(props);
        case GameProcessType.Completed: return CompletedCard(props);
    }
}

function MoneyCollectionCard(props: IProps) {
    const { userId } = useContext(GameContext);
    const { t } = useTranslation();

    const [lastPrice, setLastPrice] = useState<number>(props.gameProcess.price_locked ?? 0);
    const [highlightClass, setHighlightClass] = useState<string | null>(null);


    useEffect(() => {
        if (props.gameProcess.current_price == null) return;

        const prevPrice = lastPrice;
        const currentPrice = props.gameProcess.current_price;

        const difference = currentPrice - prevPrice;
        const newHighlightClass = difference > 0 ? 'green' : 'red';
        setHighlightClass(newHighlightClass);

        setLastPrice(props.gameProcess.current_price);

        const timeout = setTimeout(() => {
            setHighlightClass(null);
        }, 1000);

        return () => clearTimeout(timeout);

    }, [props.gameProcess.current_price])

    return (
        <Card style={{height: "100%"}}>
            <div className={style.predictionContainer}>
                <div className={`${style.predictionContainer__informationBlock} ${style.informationBlock}`}>
                    <div className={style.informationBlock_title}>
                        <span>{t('Now')}</span>
                        <div className={style.informationBlock_title_right}>
                            <a href={`https://t.me/share/url?url=https://t.me/pixie_project_bot?start=predict${props.gameProcess.game_id}_${userId}&text=${props.game.description}`}>
                                <img src={linkIcon} alt="linkIcon" />
                            </a>
                            <span>#{props.gameProcess.id}</span>
                        </div>
                    </div>
                    <div className={`${style.informationBlock_rightBlock_flex} ${style.rightBlock_flex}`}>
                        <div className={style.rightBlock_flex__element}>
                            <div className={style.rightBlock_flex__sub_element}>
                                <div className={style.rightBlock_flex__title}>{t('Players count')}</div>
                                <div className={style.rightBlock_flex__subTitle}>
                                    <span>{props.gameProcess.players_in_game}</span>
                                </div>
                            </div>
                        </div>
                        <div className={style.rightBlock_flex__divider} />
                        <div className={style.rightBlock_flex__element}>
                            <div className={style.rightBlock_flex__sub_element}>
                                <div className={style.rightBlock_flex__title}>{t('Current Price')}</div>
                                <div className={`${style.rightBlock_flex__subTitle} ${style.currentPrice} ${highlightClass == null ? "" : highlightClass == 'green' ? style.highlightGreen : style.highlightRed}`}>
                                    {
                                        props.gameProcess.current_price
                                            ?
                                            <span>
                                                ${(parseFloat((+props.gameProcess.current_price).toFixed(2))).toLocaleString("en")}
                                            </span>
                                            : props.gameProcess.price_last
                                                ?
                                                <span>
                                                    <img src={coinIcon} alt="coinIcon" />
                                                    {props.gameProcess.price_last} <MiniLoader />
                                                </span>
                                                : <MiniLoader />
                                    }
                                </div>
                            </div>
                            <div className={style.rightBlock_flex__sub_element}>
                                <div className={style.rightBlock_flex__title}>{t('Prize pool')}</div>
                                <div className={style.rightBlock_flex__subTitle}>
                                    <span>
                                        <img src={coinIcon} alt="coinIcon" />
                                        {(props.gameProcess.prize_pool ?? 0).toLocaleString("en")}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.predictionContainer_actionButtons}>
                    <UpButton title={t('Up')} subTitle={props.gameProcess.coef_up == null ? null : `${(+props.gameProcess.coef_up).toFixed(2)}x`} onClick={props.onUp} disabled={!props.canSelect} selected={props.gameProcess.in_game == true} />
                    <DownButton title={t('Down')} subTitle={props.gameProcess.coef_down == null ? null : `${(+props.gameProcess.coef_down).toFixed(2)}x`} onClick={props.onDown} disabled={!props.canSelect} selected={props.gameProcess.in_game == false} />
                </div>
            </div>
        </Card>
    )
}

function CurrentlyActiveCard(props: IProps) {
    const { theme } = useTheme();
    const { userId } = useContext(GameContext);
    const { t } = useTranslation();

    const [lastPrice, setLastPrice] = useState<number>(props.gameProcess.price_locked ?? 0);
    const [highlightClass, setHighlightClass] = useState<string | null>(null);

    const round = (value: number): number => {
        return Math.round(1000 * value) / 1000;
    };

    const priceDifference = useMemo(() => round(props.gameProcess.current_price ?? (props.gameProcess.price_last ?? 0)) - round(props.gameProcess.price_locked ?? 0), [props.gameProcess.current_price])

    useEffect(() => {
        if (props.gameProcess.current_price == null) return;

        const prevPrice = lastPrice;
        const currentPrice = props.gameProcess.current_price;

        const difference = currentPrice - prevPrice;
        const newHighlightClass = difference > 0 ? 'green' : 'red';
        setHighlightClass(newHighlightClass);

        setLastPrice(props.gameProcess.current_price);

        const timeout = setTimeout(() => {
            setHighlightClass(null);
        }, 1000);

        return () => clearTimeout(timeout);

    }, [props.gameProcess.current_price])

    return (
        <Card style={{height: "100%"}}>
            <div className={style.predictionContainer}>
                <div className={`${style.predictionContainer__informationBlock} ${style.informationBlock}`}>
                    <div className={style.informationBlock_title}>
                        <span>
                            <img src={liveIcon} alt="" />
                            {t('Live')}
                        </span>
                        <div className={style.informationBlock_title_right}>
                            <a href={`https://t.me/share/url?url=https://t.me/pixie_project_bot?start=predict${props.gameProcess.game_id}_${userId}&text=${props.game.description}`}>
                                <img src={linkIcon} alt="linkIcon" />
                            </a>
                            <span>#{props.gameProcess.id}</span>
                        </div>
                    </div>
                    <div className={`${style.informationBlock_rightBlock_flex} ${style.rightBlock_flex}`}>
                        <div className={style.rightBlock_flex__element}>
                            <div className={style.rightBlock_flex__sub_element}>
                                <div className={style.rightBlock_flex__title}>{t('Prize pool')}</div>
                                <div className={style.rightBlock_flex__subTitle}>
                                    <span>
                                        <img src={coinIcon} alt="coinIcon" />
                                        {(props.gameProcess.prize_pool ?? 0).toLocaleString("en")}
                                    </span>
                                </div>
                            </div>
                            <div className={style.rightBlock_flex__sub_element}>
                                {props.gameProcess.current_price
                                    ?
                                    <div className={`${style.rightBlock_flex__subTitle} ${style.fill}  ${priceDifference == 0 ? style.gray : priceDifference < 0 ? style.red : style.green}`}>
                                        {
                                            priceDifference == 0 ? "" :
                                                priceDifference < 0
                                                    ? <img className={`${style.rightBlock_flex__subTitle_arrowIcon}`} src={theme == 'light' ? DownFillArrowIcon : DownFillArrowLightIcon} alt="DownFillArrowIcon" />
                                                    : <img className={`${style.rightBlock_flex__subTitle_arrowIcon}`} src={theme == 'light' ? UpFillArrowIcon : UpFillArrowLightIcon} alt="UpFillArrowIcon" />
                                        }
                                        <span>
                                            ${
                                                (priceDifference < 1 && priceDifference > -1)
                                                    ? (parseFloat(priceDifference.toFixed(2))).toLocaleString("en")
                                                    : Math.round(priceDifference).toLocaleString("en")
                                            }
                                        </span>
                                    </div>
                                    : <MiniLoader />
                                }

                            </div>
                        </div>

                        <div className={style.rightBlock_flex__divider} />

                        <div className={style.rightBlock_flex__element}>
                            <div className={style.rightBlock_flex__sub_element}>
                                <div className={style.rightBlock_flex__title}>{t('Current Price')}</div>
                                <div className={`${style.rightBlock_flex__subTitle} ${style.currentPrice} ${highlightClass == null ? "" : highlightClass == 'green' ? style.highlightGreen : style.highlightRed}`}>
                                    {
                                        props.gameProcess.current_price
                                            ? <span>
                                                ${parseFloat((+props.gameProcess.current_price).toFixed(2)).toLocaleString("en")}
                                            </span>
                                            : <MiniLoader />
                                    }
                                </div>
                            </div>
                            <div className={style.rightBlock_flex__sub_element}>
                                <div className={style.rightBlock_flex__title}>{t('Locked Price')}</div>
                                <div className={style.rightBlock_flex__subTitle}>
                                    <span>${parseFloat((+(props.gameProcess.price_locked ?? 0)).toFixed(2)).toLocaleString("en")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.predictionContainer_actionButtons}>
                    <UpButton title={t('Up')} subTitle={props.gameProcess.coef_up == null ? null : `${(+props.gameProcess.coef_up).toFixed(2)}x`} disabled selected={props.gameProcess.in_game == true} />
                    <DownButton title={t('Down')} subTitle={props.gameProcess.coef_down == null ? null : `${(+props.gameProcess.coef_down).toFixed(2)}x`} disabled selected={props.gameProcess.in_game == false} />
                </div>
            </div>
        </Card>
    )
}

function CompletedCard(props: IProps) {
    const { theme } = useTheme();
    const { userId } = useContext(GameContext);
    const { t } = useTranslation();


    const isWinGame = () => {
        if (props.gameProcess.in_game == null) return;

        const winCombination = priceDifference > 0;
        return priceDifference == 0 ? null : props.gameProcess.in_game == winCombination;
    }

    const priceDifference = (props.gameProcess.price_last ?? 0) - (props.gameProcess.price_locked ?? 0);

    return (
        <Card style={{height: "100%"}}>
            <div className={style.predictionContainer}>
                <div className={`${style.predictionContainer__informationBlock} ${style.informationBlock}`}>
                    <div className={style.informationBlock_title}>
                        <span>{t('Expired')}</span>
                        <div className={style.informationBlock_title_right}>
                            <a href={`https://t.me/share/url?url=https://t.me/pixie_project_bot?start=predict${props.gameProcess.game_id}_${userId}&text=${props.game.description}`}>
                                <img src={linkIcon} alt="linkIcon" />
                            </a>
                            <span>#{props.gameProcess.id}</span>
                        </div>
                    </div>
                    {
                        props.gameProcess.in_game != null &&
                        <>
                            {
                                isWinGame() == null
                                    ? <div className={`${style.informationBlock_badge} ${style.draw}`}>
                                        <span>{t('Without changes')}</span>
                                    </div>
                                    : isWinGame()
                                        ? <div className={`${style.informationBlock_badge} ${style.won}`}>
                                            <img src={CheckedIcon} alt="CheckedIcon" />
                                            <span>{t('You Won!')}</span>
                                        </div>
                                        : <div className={`${style.informationBlock_badge} ${style.lose}`}>
                                            <img src={CrossIcon} alt="CheckedIcon" />
                                            <span>{t('You Lose')}</span>
                                        </div>
                            }
                        </>
                    }

                    <div className={`${style.informationBlock_rightBlock_flex} ${style.rightBlock_flex}`}>
                        <div className={style.rightBlock_flex__element}>
                            <div className={style.rightBlock_flex__sub_element}>
                                <div className={style.rightBlock_flex__title}>{t('Price at start')}</div>
                                <div className={style.rightBlock_flex__subTitle}>
                                    <span>${parseFloat((+(props.gameProcess.price_locked ?? 0)).toFixed(2)).toLocaleString("en")}</span>
                                </div>
                            </div>
                            <div className={style.rightBlock_flex__sub_element}>
                                <div className={style.rightBlock_flex__title}>{t('Price at end')}</div>
                                <div className={style.rightBlock_flex__subTitle}>
                                    <span>${parseFloat((+(props.gameProcess.price_last ?? 0)).toFixed(2)).toLocaleString("en")}</span>
                                </div>
                            </div>
                        </div>

                        <div className={style.rightBlock_flex__divider} />

                        <div className={style.rightBlock_flex__element}>
                            <div className={style.rightBlock_flex__sub_element}>
                                <div className={style.rightBlock_flex__title}>{t('Prize pool')}</div>
                                <div className={style.rightBlock_flex__subTitle}>
                                    <span>
                                        <img src={coinIcon} alt="coinIcon" />
                                        {(props.gameProcess.prize_pool ?? 0).toLocaleString("en")}
                                    </span>
                                </div>
                            </div>
                            <div className={style.rightBlock_flex__sub_element}>
                                <div className={`${style.rightBlock_flex__subTitle} ${style.fill} ${priceDifference == 0 ? style.gray : priceDifference < 0 ? style.red : style.green}`}>
                                    {
                                        priceDifference == 0 ? "" :
                                            priceDifference < 0
                                                ? <img className={`${style.rightBlock_flex__subTitle_arrowIcon}`} src={theme == 'light' ? DownFillArrowIcon : DownFillArrowLightIcon} alt="DownFillArrowIcon" />
                                                : <img className={`${style.rightBlock_flex__subTitle_arrowIcon}`} src={theme == 'light' ? UpFillArrowIcon : UpFillArrowLightIcon} alt="UpFillArrowIcon" />
                                    }
                                    <span>
                                        ${
                                            (priceDifference < 1 && priceDifference > -1)
                                                ? priceDifference.toFixed(2)
                                                : Math.round(priceDifference)
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.predictionContainer_actionButtons}>
                    <UpButton title={t('Up')} subTitle={props.gameProcess.coef_up == null ? null : `${(+props.gameProcess.coef_up).toFixed(2)}x`} disabled selected={props.gameProcess.in_game == true} />
                    <DownButton title={t('Down')} subTitle={props.gameProcess.coef_down == null ? null : `${(+props.gameProcess.coef_down).toFixed(2)}x`} disabled selected={props.gameProcess.in_game == false} />
                </div>
            </div>
        </Card>
    )
}
