import { Cryptocurrency, mapToCryptocurrency } from "./cryptocurrency";

export class CryptocurrencyProvider {
    public static async get(code: string, token: string): Promise<Cryptocurrency> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/cryptocurrency/price/${code}`, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
        })
            .then(response => response.json())
            .then(response => response)

        return mapToCryptocurrency(response.cryptocurrency);
    }
}