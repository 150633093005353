import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';
import { initSwipeBehavior } from "@telegram-apps/sdk-react";
import { ApexOptions } from "apexcharts";
import { DateTime } from "luxon";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Card } from "../../../../Components/Predict/card/card";
import PLoader from "../../../../Components/Predict/loader/Loader";
import GameContext from "../../../../Context/GameContext";
import { useTheme } from "../../../../Context/Predict/themeContext";
import { CryptocurrencyProvider } from "../../../../Domain/Cryptocurrency/cryptocurrencyProvider";
import { Game } from "../../../../Domain/Predict/game";
import { GameHistory } from "../../../../Domain/Predict/gameHistory";
import { GameProcess, GameProcessType } from "../../../../Domain/Predict/gameProcess";
import { PredictProvider } from "../../../../Domain/Predict/predictProdiver";
import CalendarIcon from '../../../../Resources/Predict/icons/calendar.svg';
import crown from "../../../../Resources/Predict/icons/crown.svg";
import crownDark from "../../../../Resources/Predict/icons/crown_dark.svg";
import CupIcon from "../../../../Resources/Predict/icons/cup.svg";
import CupDarkIcon from "../../../../Resources/Predict/icons/cup_dark.svg";
import FamiliesIcon from "../../../../Resources/Predict/icons/families.svg";
import FamiliesDarkIcon from "../../../../Resources/Predict/icons/families_dark.svg";
import LinkIcon from '../../../../Resources/Predict/icons/link.svg';
import LinkDarkIcon from '../../../../Resources/Predict/icons/link_dark.svg';
import StopwatchIcon from '../../../../Resources/Predict/icons/stopwatch_2.svg';
import StopwatchDarkIcon from '../../../../Resources/Predict/icons/stopwatch_2_dark.svg';
import PixieLogo from '../../../../Resources/Predict/images/PixieLogo.png';
import СoinIcon from '../../../../Resources/Predict/images/coin.svg';
import { WalletType } from "../../profile/info";
import { PredictionCard } from "./card/predictionCard";
import style from "./fastPredictionStyle.module.scss";
import { PredictionResultModal } from "./resultModal/resultModal";
import { BetType, SetPositionModal } from "./setPositionModal/setPositionModal";
import './sliderStyle.css';


interface FamilyInfo {
  name: string;
  memberCount: number;
  members: User[];
}

export interface User {
  id: number;
  avatar: string;
  name: string;
  email: string;
  wallet: WalletType | null;
  _2FAStatus: boolean;
}

export interface Comment {
  text: string;
  likeCount: number;
  user: User;
  publishDate: Date;
}

enum Tabs {
  Price = 1,
  FamiliesStats = 2,
}

enum ChartType {
  FxType = 1,
  CandlestickType = 2
}

export function FastPredictionPage() {
  const { token, score, updateGame, userId } = useContext(GameContext);
  const predictBalance = useMemo(() => parseFloat((score / 1_000_000).toFixed(2)), [score])

  let { id } = useParams();
  const { theme } = useTheme();
  const navigate = useNavigate();

  const [game, setGame] = useState<Game | null>(null);
  const [gamesProcess, setGamesProcess] = useState<GameProcess[]>([]);
  const [nowGameProcessIndex, setNowGameProcessIndex] = useState<number | null>(null);

  const [gameHistory, setGameHistory] = useState<GameHistory[]>([]);
  const [isChartLoaded, setIsChartLoaded] = useState<boolean>(false);

  const [familyInfo, setFamilyInfo] = useState<FamilyInfo>();
  const [selectedChartType, setSelectedChartType] = useState<ChartType>(ChartType.FxType);

  const [selectTab, setSelectTab] = useState<Tabs>(Tabs.Price);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [selectedBetType, setSelectedBetType] = useState<BetType | null>(null);
  const [selectedGameProcess, setSelectedGameProcess] = useState<GameProcess | null>(null);
  const [timerTime, setTimerTime] = useState<number>(0);

  const [isOpenPredictionResultModal, setIsOpenPredictionResultModal] = useState<boolean>(false);
  const [resultGameProcess, setResultGameProcess] = useState<GameProcess | null>(null);

  const [isOpenSetPositionPredictionCard, setIsOpenSetPositionPredictionCard] = useState<boolean>(false);
  const [isFirstLoad, setIsFistLoad] = useState<boolean>(true);

  const [swipeBehavior] = initSwipeBehavior();
  const { t } = useTranslation();

  const users = [
    {
      id: 1,
      avatar:
        "https://sun9-73.userapi.com/impg/79Hvt8UivjNPsKBdgICAz6pgR2AlJTyQSgeVjg/39kyN4N9Po8.jpg?size=32x32&quality=96&sign=e7b522977d4e13b7e077fd320a2321c0&type=album",
      name: "s900_WE",
      email: "john.doe@example.com",
      wallet: null,
      _2FAStatus: false,
    },
    {
      id: 1,
      avatar:
        "https://sun9-73.userapi.com/impg/79Hvt8UivjNPsKBdgICAz6pgR2AlJTyQSgeVjg/39kyN4N9Po8.jpg?size=32x32&quality=96&sign=e7b522977d4e13b7e077fd320a2321c0&type=album",
      name: "s900_WE",
      email: "john.doe@example.com",
      wallet: null,
      _2FAStatus: false,
    },
    {
      id: 1,
      avatar:
        "https://sun9-73.userapi.com/impg/79Hvt8UivjNPsKBdgICAz6pgR2AlJTyQSgeVjg/39kyN4N9Po8.jpg?size=32x32&quality=96&sign=e7b522977d4e13b7e077fd320a2321c0&type=album",
      name: "s900_WE",
      email: "john.doe@example.com",
      wallet: null,
      _2FAStatus: false,
    },
    {
      id: 1,
      avatar:
        "https://sun9-73.userapi.com/impg/79Hvt8UivjNPsKBdgICAz6pgR2AlJTyQSgeVjg/39kyN4N9Po8.jpg?size=32x32&quality=96&sign=e7b522977d4e13b7e077fd320a2321c0&type=album",
      name: "s900_WE",
      email: "john.doe@example.com",
      wallet: null,
      _2FAStatus: false,
    },
  ];

  const comments: Comment[] = [
    { likeCount: 10, publishDate: new Date("2024-07-31 12:00:12"), text: "Hi Alex! Absolutely, happy to help. Try focusing on your grip and foot positioning.", user: users[0] },
    { likeCount: 10, publishDate: new Date("2024-07-30 18:10:12"), text: "Hi Alex! Absolutely, happy to help. Try focusing on your grip and foot positioning.", user: users[0] },
    { likeCount: 10, publishDate: new Date("2024-07-29 18:10:12"), text: "Hi Alex! Absolutely, happy to help. Try focusing on your grip and foot positioning.", user: users[0] },
    { likeCount: 10, publishDate: new Date("2024-07-28 18:10:12"), text: "Hi Alex! Absolutely, happy to help. Try focusing on your grip and foot positioning.", user: users[0] },
    { likeCount: 10, publishDate: new Date("2024-07-10 18:10:12"), text: "Hi Alex! Absolutely, happy to help. Try focusing on your grip and foot positioning.", user: users[0] },
    { likeCount: 10, publishDate: new Date("2024-06-31 18:10:12"), text: "Hi Alex! Absolutely, happy to help. Try focusing on your grip and foot positioning.", user: users[0] }
  ];

  useEffect(() => {
    if (swipeBehavior.supports("disableVerticalSwipe")) swipeBehavior.disableVerticalSwipe();

    const updatePlayerCountInterval = setInterval(() => { updateGameProcess(); }, 5_000);
    const updateTimeTimerInterval = setInterval(() => { loadCurrentPrice(); }, 5_000);

    return () => {
      clearInterval(updatePlayerCountInterval);
      clearInterval(updateTimeTimerInterval);

      if (swipeBehavior.supports("enableVerticalSwipe")) swipeBehavior.enableVerticalSwipe();
    }
  }, [])

  useEffect(() => {
    if (selectedGameProcess == null) return;

    setSelectedGameProcess(gamesProcess.find(g => g.id == selectedGameProcess.id) ?? null);

  }, [JSON.stringify(gamesProcess)])

  async function updateGameProcess() {
    if (id == null) return;

    const gamesProcessResult = await PredictProvider.getGameProcess(+id, token!);
    const liveGame = gamesProcessResult.gamesProcess.find(g => g.type == GameProcessType.MoneyCollection);
    if (liveGame == null) return;

    const playersCount = liveGame?.players_in_game;
    const prize_pool = liveGame?.prize_pool;
    const coef_down = liveGame?.coef_down;
    const coef_up = liveGame?.coef_up;

    setGamesProcess(prev => {
      return prev.map(game => {
        if (game.id == liveGame.id) return { ...game, players_in_game: playersCount, prize_pool: prize_pool, coef_down: coef_down, coef_up: coef_up };
        return game;
      });
    });
  }

  useEffect(() => {
    loadGame();
    loadGameProcessAndStartInterval();
    loadGameHistory();
  }, [])

  async function loadGame() {
    if (id == null) return;

    setIsLoaded(false);
    const { game } = await PredictProvider.getGame(+id, token!);
    setGame(game);

    setIsLoaded(true);
  }

  async function loadGameProcessAndStartInterval() {
    if (id == null) return;

    const intervalTimerId = setInterval(() => {
      setTimerTime((prevTime) => prevTime <= 0 ? 0 : prevTime - 1);
    }, 1_000);

    let updateInterval: NodeJS.Timer;

    const gamesProcessResult = await PredictProvider.getGameProcess(+id, token!);
    const gamesProcess = gamesProcessResult.gamesProcess;
    setGamesProcess(gamesProcess.sort((a, b) => a.id - b.id));

    const nowGameIndex = gamesProcess.findIndex(g => g.type == GameProcessType.MoneyCollection);
    setNowGameProcessIndex(nowGameIndex);

    const liveGame = gamesProcess.find(g => g.type == GameProcessType.MoneyCollection) ?? null;
    let timeTimer = getTimeByLiveGame(liveGame, gamesProcessResult.datetime_now);

    if (liveGame != null) {
      setTimeout(() => {
        setTimeout(() => {
          loadGamesProcess();
          loadGameHistory();
        }, 5000)

        const executeWithDelay = () => {
          setTimeout(() => {
            loadGamesProcess();
            loadGameHistory();
          }, 5000);
        };

        updateInterval = setInterval(executeWithDelay, (isBTC ? 1 : 5) * 60 * 1000);

      }, timeTimer * 1000);
    }

    setTimerTime(timeTimer + 5);
    setIsLoaded(true);

    return () => {
      clearInterval(intervalTimerId);
      clearInterval(updateInterval);
    };
  }

  useEffect(() => {
    sendGameNotification();
    loadCurrentPrice();
  }, [JSON.stringify(gamesProcess.map(g => g.id))])

  function sendGameNotification() {
    if (gamesProcess.length == 0) return;
    if (isFirstLoad) return setIsFistLoad(false);

    const prevGame = GameProcess.length > 2 ? gamesProcess[2] : null;
    if (prevGame == null || prevGame.in_game == null) return;

    updateBalace();
    setResultGameProcess(prevGame);
    setIsOpenPredictionResultModal(true);
  }

  async function loadCurrentPrice() {
    const cryptocurrency = await CryptocurrencyProvider.get(isBTC ? 'btc' : 'eth', token!);;
    const current_price = cryptocurrency.price;

    setGamesProcess(prev => {
      return prev.map(game => {
        if (game.type === GameProcessType.CurrentlyActive || game.type === GameProcessType.MoneyCollection) {
          return { ...game, current_price: current_price };
        }
        return game;
      });
    });

    setTimeout(() => {
      setGamesProcess(prev => {
        return prev.map(game => {
          if (game.type === GameProcessType.CurrentlyActive || game.type === GameProcessType.MoneyCollection) {
            let direction = true;
            if (game.price_locked != null) {
              const difference = ((game.current_price ?? (game.price_last ?? 0)) - game.price_locked)
              direction = difference < 0 ? false : true;
            }

            const persentCurrentPrice = direction ? current_price + (current_price * 0.005 / 100) : current_price - (current_price * 0.01 / 100);
            return { ...game, current_price: persentCurrentPrice };
          }
          return game;
        });
      });
    }, 2_500)
  }

  async function loadGamesProcess() {
    if (id == null) return;

    const gamesProcessResult = await PredictProvider.getGameProcess(+id, token!);
    const gamesProcess = gamesProcessResult.gamesProcess;
    setGamesProcess(gamesProcess.sort((a, b) => a.id - b.id));

    const nowGameIndex = gamesProcess.findIndex(g => g.type == GameProcessType.MoneyCollection);
    setNowGameProcessIndex(nowGameIndex);

    const liveGame = gamesProcess.find(g => g.type == GameProcessType.MoneyCollection) ?? null;
    let timeTimer = getTimeByLiveGame(liveGame, gamesProcessResult.datetime_now);
    setTimerTime(timeTimer + 5);

    setIsLoaded(true);
  }

  function getTimeByLiveGame(liveGame: GameProcess | null, datetime_now: Date): number {
    let timeTimer = ((isBTC ? 1 : 5) * 60);
    if (liveGame != null) {
      const startDate = liveGame.updated_at!;
      const now = datetime_now;

      const dif = now.getTime() - startDate.getTime();
      const remained = ((isBTC ? 1 : 5) * 60 * 1000) - dif;

      timeTimer = (Math.round(remained / 1000));
    }

    return timeTimer;
  }

  async function updateBalace() {
    const balance = await PredictProvider.getBalance(token!);
    updateGame({ score: parseFloat(balance.toString()) });
  }

  async function loadGameHistory() {
    if (id == null) return;

    setIsChartLoaded(false);

    const gameHistory = await PredictProvider.getGameGraphHistory(+id, token!);
    setGameHistory(gameHistory);

    setIsChartLoaded(true);
  }

  useEffect(() => {
    setFamilyInfo({
      name: "Family A",
      memberCount: 10,
      members: users,
    });
  }, []);

  function formattedNumber(number: number) {
    return number.toLocaleString("en");
  }

  const sliderRef = useRef<HTMLDivElement | null>(null);
  const sliderRefMobile = useRef<HTMLDivElement | null>(null);
  const glideRef = useRef<Glide | null>(null);

  useEffect(() => {
    if (sliderRef.current) {
      glideRef.current = new Glide(sliderRef.current, {
        type: 'slider',
        startAt: nowGameProcessIndex ?? 0,
        focusAt: 'center',
        perView: 1.1,
        gap: 10,
        swipeThreshold: 80,
        dragThreshold: 120,
        animationDuration: 600,
        rewind: false,
        breakpoints: {
          1330: { perView: 1.1, gap: 10 },
          1150: { perView: 1.1, gap: 10 },
          730: { perView: 1.9, gap: 10 },
          550: { perView: 1.5, gap: 10 },
          480: { perView: 1.1, gap: 10 },
        },

      });
      glideRef.current.mount();
    }
    if (sliderRefMobile.current) {
      glideRef.current = new Glide(sliderRefMobile.current, {
        type: 'slider',
        startAt: nowGameProcessIndex ?? 0,
        focusAt: 'center',
        perView: 1.1,
        gap: 10,
        swipeThreshold: 80,
        dragThreshold: 120,
        animationDuration: 600,
        rewind: false,
        breakpoints: {
          1330: { perView: 1.1, gap: 10 },
          1150: { perView: 1.1, gap: 10 },
          730: { perView: 1.9, gap: 10 },
          550: { perView: 1.5, gap: 10 },
          480: { perView: 1.1, gap: 10 },
        },

      });
      glideRef.current.mount();
    }

    return () => {
      if (glideRef.current) {
        glideRef.current.destroy();
      }
    };
  }, [nowGameProcessIndex]);

  const lineSeries: ApexAxisChartSeries = [
    {
      name: "Price Last",
      data: gameHistory.map(item => ({
        x: item.locked_at,
        y: item.price_last
      }))
    },
    {
      name: "Price Locked",
      data: gameHistory.map(item => ({
        x: item.locked_at,
        y: item.price_locked
      }))
    }
  ];

  const lineOptions: ApexOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
    },
    colors: theme == "light" ? ["#BAF6D2", "#A7F16B"] : ["#D096FF", "#7B66E1"],
    stroke: {
      curve: "smooth",
      width: 2.5,
    },
    markers: {
      hover: {
        sizeOffset: 4
      }
    },
    yaxis: {
      opposite: true,
      labels: {
        style: {
          cssClass: style.priceCharts_lables,
        },
        formatter: (val: number) => `${formattedNumber(val)}`,
      },
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      tickAmount: 1,
      axisTicks: {
        show: false,
      },
      min: gameHistory.length > 0 ? gameHistory[gameHistory.length - 1].locked_at.getTime() : new Date().getTime(),
      max: gameHistory.length > 0 ? gameHistory[0].locked_at.getTime() : new Date().getTime(),
      labels: {
        datetimeUTC: false,
        trim: false,
        format: 'HH:mm',
        hideOverlappingLabels: true,
        style: {
          cssClass: style.priceCharts_lables,
        },
      },
      tooltip: {
        enabled: false
      }
    },
    grid: {
      position: "back",
      borderColor: "rgba(201, 203, 205, 0.18)",
      strokeDashArray: 2.5,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: false,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      hideEmptySeries: true,
      fillSeriesColor: false,
      style: {
        fontSize: '12px',
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: false
      },
      y: {
        formatter: function (value) {
          return `${formattedNumber(value)}`;
        }
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const value = series[seriesIndex][dataPointIndex];
        return `<div 
        style="padding: 5px 12px; 
        color: #1f1e36; 
        font-size: 12px; 
        border-radius: 81px; 
        background: ${theme == "light" ? "rgba(250, 250, 250, 0.63);" : "#ede0eb;"} ">${formattedNumber(value)}</div>`;
      },
      marker: {
        show: true,
      },
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 0,
        offsetY: 0,
      },
    },
    legend: {
      show: false,
    }
  };

  async function closeSetPositionModal(inBet: boolean, gameProcessId: number, betType: BetType, betPrice: number) {
    setIsOpenSetPositionPredictionCard(false);
    setSelectedBetType(null);
    setSelectedGameProcess(null);

    if (inBet) {
      toast.success("Success!");

      setGamesProcess(prev => {
        return prev.map(game => {
          if (game.id == gameProcessId) {
            return { ...game, in_game: betType == BetType.Up, players_in_game: (game.players_in_game ?? 0) + 1, prize_pool: (game.prize_pool ?? 0) + betPrice };
          }
          return game;
        });
      });
    }
  }

  function setDownPosition(gameProcess: GameProcess) {
    setIsOpenSetPositionPredictionCard(true);
    setSelectedBetType(BetType.Down);
    setSelectedGameProcess(gameProcess);
  }

  function setUpPosition(gameProcess: GameProcess) {
    setIsOpenSetPositionPredictionCard(true);
    setSelectedBetType(BetType.Up);
    setSelectedGameProcess(gameProcess);
  }

  function formatTime(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  }

  function closePredictionResultModal() {
    setIsOpenPredictionResultModal(false);
    setResultGameProcess(null);
  };

  const lastPrice = gameHistory.length != 0 ? gameHistory[gameHistory.length - 1].price_last : 0;
  const prevPrice = gameHistory.length != 0 ? gameHistory[gameHistory.length - 2].price_last : 0;

  const priceChangePercent = ((lastPrice - prevPrice) / prevPrice) * 100;

  const isBTC = +id! == 1;

  if (!isLoaded) return <PLoader />

  return (
    <div className={style.mainPageContainer}>
      <div className={style.header}>
        <div className={style.header_logo} onClick={() => navigate("/predict/predictions")}>
          <img src={PixieLogo} alt="PixieLogo" />
        </div>
        <div className={style.header_rightBlock}>
          <div className={`${style.header_rightBlock_element} ${style.balance}`} onClick={() => navigate("/predict/profile/game-history")}>
            <div>
              <img src={СoinIcon} alt="СoinIcon" />
              <span className={style.header_rightBlock_element_subTitle}>{t('Balance')}</span>
            </div>
            <div className={style.header_rightBlock_element_title}>
              {predictBalance.toLocaleString("en")}
            </div>
          </div>
          <div className={style.header_rightBlock_element}>
            <div>
              <img src={theme == "light" ? StopwatchIcon : StopwatchDarkIcon} alt="StopwatchIcon" />
              <span className={style.header_rightBlock_element_title}>{timerTime == 0 ? "00:00" : formatTime(timerTime)}</span>
            </div>
            <span className={style.header_rightBlock_element_subTitle}> {isBTC ? 1 : 5} m</span>
          </div>
        </div>
      </div>

      <div className={style.mainBlockMobile}>
        {
          game != null &&
          <Card className={style.mainBlockMobile_PredictionContainer_card}>
            <div className={style.mainBlockMobile_PredictionContainer_card_leftContent}>
              <img src={game.image ? `${process.env.REACT_APP_FILE_STORAGE_URL}/${game.image}` : ""} alt="PredictionImage" />
            </div>
            <div className={style.mainBlockMobile_PredictionContainer_card_rightContent}>
              <div className={style.mainBlockMobile_PredictionContainer_card_rightContent_header}>
                <div className={style.mainBlockMobile_PredictionContainer_card_rightContent_header_title}>
                  <span>
                    Created by {game.creator_telegram_id == -1
                      ? <span >System</span>
                      : (game.last_name != null || game.first_name != null)
                        ? <span >
                          {game.last_name != null && game.last_name}
                          &nbsp;
                          {game.first_name != null && game.first_name}
                        </span>
                        : <span>System</span>
                    }
                  </span>
                </div>
                <div className={style.mainBlockMobile_PredictionContainer_card_rightContent_header_buttons}>
                  <a href={`https://t.me/share/url?url=https://t.me/pixie_project_bot?start=predict${game.id}_${userId}&text=${game.description}`}>
                    {
                      theme == "dark"
                        ? <img src={LinkDarkIcon} alt="LinkIcon" />
                        : <img src={LinkIcon} alt="LinkIcon" />
                    }
                  </a>
                </div>
              </div>
              <div className={style.mainBlockMobile_PredictionContainer_card_rightContent_title}>
                {game.name}
              </div>
              <div className={style.mainBlockMobile_PredictionContainer_card_rightContent_info}>
                <div>
                  <img src={CalendarIcon} alt="CalendarIcon" />
                  {game.start_at != null && <span>{DateTime.fromJSDate(game.start_at).toFormat('MMM d, yyyy')}</span>}
                </div>
              </div>
            </div>
          </Card>
        }

        <div className={style.mainBlockMobile_main}>
          <Card className={style.priceCharts}>
            <div className={style.priceCharts_header}>
              <div className={style.priceCharts_header_title}>
                <div className={style.priceCharts_header_title_main}>
                  {
                    gameHistory.length > 0 &&
                    <>${parseFloat((+gameHistory[gameHistory.length - 1].price_last).toFixed(3)).toLocaleString("en")}</>
                  }
                </div>
                <div className={style.priceCharts_header_title_sub}>
                  {priceChangePercent > 0 && "+"} {priceChangePercent.toLocaleString("en")}%
                </div>
              </div>
            </div>
            {
              isChartLoaded
                ? <ReactApexChart
                  options={lineOptions}
                  series={lineSeries}
                  type="line"
                  height="100%"
                  width="100%"
                />
                : <PLoader />
            }
          </Card>
          {
            nowGameProcessIndex != null && game != null &&
            <div className="slider-container">
              <div ref={sliderRefMobile} className={`glide ${style.mainPageContainer_deals_slider}`}>
                <div className="glide__track" data-glide-el="track">
                  <ul className="glide__slides">
                    {gamesProcess.map((gameProcess, index) => (
                      <li className="glide__slide" key={index}>
                        <PredictionCard
                          game={game}
                          gameProcess={gameProcess}
                          onDown={() => setDownPosition(gameProcess)}
                          onUp={() => setUpPosition(gameProcess)}
                          canSelect={gameProcess.in_game == null}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          }
        </div>
        {/* 
        <div className={style.commentBlock}>
          <CommentBlock comments={comments} onSendComment={() => { }} addCommentInputColor='dark' />
        </div> */}
      </div>

      {
        nowGameProcessIndex != null && game != null &&
        // <div className="slider-container">
        //   <Slider {...sliderSettings} initialSlide={nowGameProcessIndex} className={style.mainPageContainer_deals_slider}>
        //     {
        //       gamesProcess.map((gameProcess, index) => (
        //         <PredictionCard
        //           key={index}
        //           game={game}
        //           gameProcess={gameProcess}
        //           onDown={() => setDownPosition(gameProcess)}
        //           onUp={() => setUpPosition(gameProcess)}
        //           // canSelect={isWebSocketActive && gameProcess.in_game == null}
        //           canSelect={gameProcess.in_game == null}
        //         />
        //       ))
        //     }
        //   </Slider>
        // </div>

        <div className={`slider-container ${style.sliderContainer}`}>
          <div ref={sliderRef} className={`glide ${style.mainPageContainer_deals_slider}`}>
            <div className="glide__track" data-glide-el="track">
              <ul className="glide__slides">
                {gamesProcess.map((gameProcess, index) => (
                  <li className="glide__slide" key={index}>
                    <PredictionCard
                      game={game}
                      gameProcess={gameProcess}
                      onDown={() => setDownPosition(gameProcess)}
                      onUp={() => setUpPosition(gameProcess)}
                      canSelect={gameProcess.in_game == null}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      }

      <div className={style.mainBlock}>
        <div className={style.tabControl}>
          <div
            className={`${style.tabControl_item}  ${selectTab === Tabs.Price ? style.tabControl_itemActive : ""
              }`}
            onClick={() => setSelectTab(Tabs.Price)}
          >
            {t('Price chart')}
          </div>
          {/* <div
            className={`${style.tabControl_item} ${selectTab === Tabs.FamiliesStats ? style.tabControl_itemActive : ""
              }`}
            onClick={() => setSelectTab(Tabs.FamiliesStats)}
          >
            Stats on the Pixie families
          </div> */}
        </div>
        {
          selectTab === Tabs.Price && (
            <Card className={style.priceCharts}>
              <div className={style.priceCharts_header}>
                <div className={style.priceCharts_header_title}>
                  <div className={style.priceCharts_header_title_main}>
                    {
                      gameHistory.length > 0 &&
                      <>${parseFloat((+gameHistory[gameHistory.length - 1].price_last).toFixed(3)).toLocaleString("en")}</>
                    }
                  </div>
                  <div className={style.priceCharts_header_title_sub}>
                    {priceChangePercent > 0 && "+"} {priceChangePercent.toLocaleString("en")}%
                  </div>
                </div>
                {/* <div className={style.priceCharts_header_buttons}>
                    <span onClick={() => { }}>1m</span>
                    <img
                      onClick={selectCandlestickChartType}
                      src={theme === 'dark' ? CandlestickLightIcon : CandlestickIcon}
                      alt="CandlestickIcon"
                    />
                    <img onClick={selectFxChartType} src={theme === "dark" ? FxLightIcon : FxIcon} alt="FxIcon" />
                  </div> */}
              </div>
              {/* <div className={style.priceCharts_swapButton}>
                <img src={SwapArrow} alt="SwapArrow" />
                <span>BTC/BUSD</span>
              </div> */}
              {/* {
                  selectedChartType === ChartType.FxType
                    ? <ReactApexChart
                      options={lineOptions}
                      series={lineSeries}
                      type="line"
                      height="100%"
                    />
                    :
                    <ReactApexChart
                      options={lineOptions}
                      series={lineSeries}
                      type="line"
                      height="100%"
                    />
                } */}

              {
                isChartLoaded
                  ? <ReactApexChart
                    options={lineOptions}
                    series={lineSeries}
                    type="line"
                    height="100%"
                    width="100%"
                  />
                  : <PLoader />
              }
            </Card>
          )
        }

        {
          selectTab === Tabs.FamiliesStats && (
            <div className={style.familiesStats}>
              <Card>
                <div className={style.familiesStats_values}>
                  <div className={style.familiesStats_value}>
                    {
                      theme === "dark"
                        ? <img src={FamiliesDarkIcon} alt="families_dark" />
                        : <img src={FamiliesIcon} alt="families" />
                    }
                    <div className={style.familiesStats_text}>
                      <div className={style.familiesStats_title}>
                        Total Families:{" "}
                      </div>
                      <div className={style.familiesStats_subTitle}>15</div>
                    </div>
                  </div>
                  <div className={style.familiesStats_value}>
                    {
                      theme === "dark"
                        ? <img src={crownDark} alt="crownDark" />
                        : <img src={crown} alt="crown" />
                    }

                    <div className={style.familiesStats_text}>
                      <div className={style.familiesStats_title}>
                        Total Bets Placed:
                      </div>
                      <div className={style.familiesStats_subTitle}>5000 PIX</div>
                    </div>
                  </div>
                </div>
                {
                  familyInfo != null && (
                    <div className={style.familiesStats_rating}>
                      <div className={style.familiesStats_familyInfo}>
                        <div className={style.familiesStats_familyMembers}>
                          {familyInfo.members.map((member, index) => (
                            <img
                              key={index}
                              src={member.avatar}
                              className="avatar"
                            />
                          ))}
                          {familyInfo.memberCount > 4 && (
                            <div className={style.familiesStats_extraMember}>
                              +{familyInfo.memberCount - 4}
                            </div>
                          )}
                          <div className={style.familiesStats_familyName}>
                            {familyInfo.name}
                          </div>
                        </div>
                        <div>
                          {
                            theme === "dark"
                              ? <img src={CupDarkIcon} alt="cup" />
                              : <img src={CupIcon} alt="cup" />
                          }
                        </div>
                      </div>
                      <div className={style.familiesStats_statistics}>
                        <div className={style.familiesStats_statisticsItem}>
                          <div className={style.familiesStats_statisticsItemTitle}>
                            Bet Amount
                          </div>
                          <div
                            className={style.familiesStats_statisticsItemSubTitle}
                          >
                            1000 PIX
                          </div>
                        </div>

                        <div className={style.divider}></div>

                        <div className={style.familiesStats_statisticsItem}>
                          <div className={style.familiesStats_statisticsItemTitle}>
                            Prediction
                          </div>
                          <div
                            className={style.familiesStats_statisticsItemSubTitle}
                          >
                            Option 1
                          </div>
                        </div>
                        <div className={style.divider}></div>

                        <div className={style.familiesStats_statisticsItem}>
                          <div className={style.familiesStats_statisticsItemTitle}>
                            Win/Loss
                          </div>
                          <div
                            className={style.familiesStats_statisticsItemSubTitle}
                          >
                            10W/5L
                          </div>
                        </div>

                        <div className={style.divider}></div>

                        <div className={style.familiesStats_statisticsItem}>
                          <div className={style.familiesStats_statisticsItemTitle}>
                            % of Total Bet
                          </div>
                          <div
                            className={style.familiesStats_statisticsItemSubTitle}
                          >
                            15%
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </Card>
            </div>
          )
        }
        {/* 
        <div className={style.commentBlock}>
          <CommentBlock comments={comments} onSendComment={() => { }} addCommentInputColor='dark' />
        </div> */}
      </div>

      {
        resultGameProcess != null &&
        <PredictionResultModal isOpen={isOpenPredictionResultModal} gameProcess={resultGameProcess} onClose={closePredictionResultModal} />
      }

      {
        selectedBetType != null && selectedGameProcess != null &&
        <SetPositionModal betType={selectedBetType} isOpen={isOpenSetPositionPredictionCard} gameProcess={selectedGameProcess} onClose={closeSetPositionModal} />
      }
      <div><Toaster /></div>
    </div>
  );
}
