export class Family {
    constructor(
        public id: number,
        public creator_id: number,
        public name: string,
        public description: string,
        public level: number,
        public created_at: Date,
        public updated_at: Date | null,
        public image: string,
        public users_count: number,
        public user_id_in_family: number,
        public first_name: string,
        public last_name: string,
        public username: string,
        public bets_count: number
    ) { }
}

export function mapToFamily(data: any) {
    return new Family(
        data.id,
        data.creator_id,
        data.name,
        data.description,
        data.level,
        data.created_at,
        data.updated_at,
        data.image,
        data.users_count,
        data.user_id_in_family,
        data.first_name,
        data.last_name,
        data.username,
        data.bets_count != null ? parseInt(data.bets_count) : 0
    )
}