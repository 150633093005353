import { ApexOptions } from 'apexcharts'
import { DateTime } from 'luxon'
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Accordion } from '../../../../Components/Predict/accordion/accordion'
import { DownButton } from '../../../../Components/Predict/actionButton/downButton'
import { UpButton } from '../../../../Components/Predict/actionButton/upButton'
import { Card } from '../../../../Components/Predict/card/card'
import PLoader from '../../../../Components/Predict/loader/Loader'
import GameContext from '../../../../Context/GameContext'
import { useTheme } from '../../../../Context/Predict/themeContext'
import { CustomGameProcess } from '../../../../Domain/Predict/customGameProcess'
import { Game } from '../../../../Domain/Predict/game'
import { PredictProvider } from '../../../../Domain/Predict/predictProdiver'
import CalendarIcon from '../../../../Resources/Predict/icons/calendar.svg'
import CoinIcon from '../../../../Resources/Predict/icons/coin.svg'
import InfoIcon from '../../../../Resources/Predict/icons/info.svg'
import InfoDarkIcon from '../../../../Resources/Predict/icons/info_dark.svg'
import LinkIcon from '../../../../Resources/Predict/icons/link.svg'
import LinkDarkIcon from '../../../../Resources/Predict/icons/link_dark.svg'
import LockDarkIcon from '../../../../Resources/Predict/icons/lock_dark.svg'
import LockLightIcon from '../../../../Resources/Predict/icons/lock_light.svg'
import MinusIcon from '../../../../Resources/Predict/icons/minus.svg'
import MinusDarkIcon from '../../../../Resources/Predict/icons/minus_dark.svg'
import PlusIcon from '../../../../Resources/Predict/icons/plus.svg'
import PlusDarkIcon from '../../../../Resources/Predict/icons/plus_dark.svg'
import PixieLogo from '../../../../Resources/Predict/images/PixieLogo.png'
import СoinIcon from '../../../../Resources/Predict/images/coin.svg'
import { Comment } from '../fast/fastPredictionPage'
import { BetType } from '../fast/setPositionModal/setPositionModal'
import style from './customPredictionStyle.module.scss'

enum Tabs {
    Buy = 1,
    Sell = 2
}

export function CustomPredictionPage() {
    const { token, score, updateGame, userId } = useContext(GameContext);
    const predictBalance = useMemo(() => Math.round(score / 1_000_000), [score])

    let { id } = useParams();
    const { theme } = useTheme();

    const [game, setGame] = useState<Game | null>(null);
    const [gameProcess, setGameProcess] = useState<CustomGameProcess | null>(null);
    const [dateTimeUtcNow, setDateTimeUtcNow] = useState<Date>(new Date());

    const [selectedBetType, setSelectedBetType] = useState<BetType | null>(null);
    const [betPrice, setBetPrice] = useState<number | null>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        loadGameInfo();
    }, [])

    useEffect(() => {
        if (game?.id == null) return;

        const updateInterval = setInterval(() => { loadGameProcess(game.id) }, 10_000)

        return () => {
            clearInterval(updateInterval);
        }

    }, [game?.id])

    async function loadGameInfo() {
        if (id == null) return;

        setIsLoading(true);
        const { game, datetime_now } = await PredictProvider.getGame(+id, token!);
        setGame(game);
        setDateTimeUtcNow(datetime_now);

        await loadGameProcess(game.id);

        setIsLoading(false);
    }

    async function loadGameProcess(gameId: number) {
        const customGameProcess = await PredictProvider.getCustomGameProcess(gameId, token!);
        setGameProcess(customGameProcess);

        if (customGameProcess.user_in_game && customGameProcess.user_type_bet != null) {
            setSelectedBetType(customGameProcess.user_type_bet ? BetType.Up : BetType.Down);
        }


    }

    function minusAmountEvent() {
        if (gameProcess == null) return;
        if (gameProcess.user_in_game) return;
        if (predictionInActive()) return;

        if ((betPrice ?? 0) - 1 < 0) return;

        setBetPrice(prev => (prev ?? 0) - 1)
    }

    function plusAmountEvent() {
        if (gameProcess == null) return;
        if (gameProcess.user_in_game) return;
        if (predictionInActive()) return;

        setBetPrice(prev => (prev ?? 0) + 1)
    }

    const fundAllocationPie = [
        { persent: 2.9, text: t("Commission to the licence holders") },
        { persent: 6, text: t("Commission to the NFT house owners") },
        { persent: 6, text: t("Commission to the NFT landlords") },
        { persent: 5, text: t("Staking fund") },
        { persent: 1, text: t("Charity fund") },
        { persent: 1, text: t("Commission of the game creator") },
        { persent: 1, text: t("Lottery pool") },
        { persent: 1, text: t("Referral bonus") },
        { persent: 0.7, text: t("Pool for Pixie Family Contests") },
        { persent: 0.1, text: t("Burn") },
        { persent: 0.2, text: t("to 5 metaverse street Diamond houses owners") },
        { persent: 0.1, text: t("Project fee") },
    ]

    const users = [
        { id: 1, avatar: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1200px-Circle-icons-profile.svg.png", name: "s900_WE", email: "john.doe@example.com", wallet: null, _2FAStatus: false },
        { id: 1, avatar: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1200px-Circle-icons-profile.svg.png", name: "s900_WE", email: "john.doe@example.com", wallet: null, _2FAStatus: false },
        { id: 1, avatar: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1200px-Circle-icons-profile.svg.png", name: "s900_WE", email: "john.doe@example.com", wallet: null, _2FAStatus: false },
        { id: 1, avatar: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1200px-Circle-icons-profile.svg.png", name: "s900_WE", email: "john.doe@example.com", wallet: null, _2FAStatus: false },
    ];

    const comments: Comment[] = [
        { likeCount: 10, publishDate: new Date("2024-07-31 12:00:12"), text: "Hi Alex! Absolutely, happy to help. Try focusing on your grip and foot positioning.", user: users[0] },
        { likeCount: 10, publishDate: new Date("2024-07-30 18:10:12"), text: "Hi Alex! Absolutely, happy to help. Try focusing on your grip and foot positioning.", user: users[0] },
        { likeCount: 10, publishDate: new Date("2024-07-29 18:10:12"), text: "Hi Alex! Absolutely, happy to help. Try focusing on your grip and foot positioning.", user: users[0] },
        { likeCount: 10, publishDate: new Date("2024-07-28 18:10:12"), text: "Hi Alex! Absolutely, happy to help. Try focusing on your grip and foot positioning.", user: users[0] },
        { likeCount: 10, publishDate: new Date("2024-07-10 18:10:12"), text: "Hi Alex! Absolutely, happy to help. Try focusing on your grip and foot positioning.", user: users[0] },
        { likeCount: 10, publishDate: new Date("2024-06-31 18:10:12"), text: "Hi Alex! Absolutely, happy to help. Try focusing on your grip and foot positioning.", user: users[0] }
    ];

    const series = [
        {
            name: 'Data',
            data: [10, 40, 25, 50, 40, 60, 30],
        },
    ];

    const options: ApexOptions = {
        chart: {
            height: "100%",
            type: 'line',
            toolbar: {
                show: false,
            },
            // dropShadow: {
            //     enabled: true,
            //     top: 5,
            //     left: 0,
            //     blur: 5,
            //     opacity: 0.3,
            //     color: '#079BE2',
            // },
            zoom: {
                enabled: false
            },
        },

        stroke: {
            curve: 'smooth',
            width: 2,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                type: 'horizontal',
                shade: 'dark',
                gradientToColors: ['#ff00ff'],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
            },
        },
        xaxis: {
            categories: ['Oct 1', 'Nov 1', 'Dec 1', 'Jan 1', 'Feb 1', 'Mar 1'],
            labels: {
                style: {
                    cssClass: style.customPredictionContainer_detailedInformationBlock_graph_lables
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            opposite: true,
            labels: {
                style: {
                    cssClass: style.customPredictionContainer_detailedInformationBlock_graph_lables
                },
                formatter: (val: number) => `${val}%`
            },
        },
        grid: {
            position: 'back',
            xaxis: {
                lines: {
                    show: true
                }
            },
            yaxis: {
                lines: {
                    show: false
                },

            }
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            enabled: false,
        },
    };

    function handleBetPriceChange(event: ChangeEvent<HTMLInputElement>) {
        const value = event.target.value.replace(",", "");
        const numberValue = value == "" ? 0 : parseInt(value);
        if (isNaN(numberValue)) return;

        setBetPrice(numberValue);
    }

    async function confirmPrediction() {
        if (id == null) return;
        if (gameProcess == null) return;
        if (selectedBetType == null) return;
        if (gameProcess.user_in_game) return;
        if (betPrice == null) return;
        if (predictBalance < betPrice) return;
        if (betPrice == 0 || betPrice < 0) return;
        if (predictionInActive()) return;

        setIsLoading(true);
        const response = await PredictProvider.bet(selectedBetType, gameProcess.game_process_id, betPrice, token!);
        if (response.message == 'ok') {
            updateGame({
                score: score - betPrice * 1_000_000
            })
            toast.success("Success");
            setBetPrice(0);
        }
        else {
            toast.error("Error :c");
        }

        await loadGameProcess(game!.id);
        setIsLoading(false);
    }

    const predictionInActive = () => game?.end_at != null && dateTimeUtcNow > game.end_at;

    if (isLoading) return <PLoader />

    return (
        <div className={style.customPredictionContainer}>
            <div className={style.header}>
                <div className={style.header_logo} onClick={() => navigate("/predict/predictions")}>
                    <img src={PixieLogo} alt="PixieLogo" />
                </div>
                <div className={style.header_rightBlock}>
                    <div className={style.header_rightBlock_element} onClick={() => navigate("/predict/profile/game-history")}>
                        <div>
                            <img src={СoinIcon} alt="СoinIcon" />
                            <span className={style.header_rightBlock_element_subTitle}>{t('Balance')}</span>
                        </div>
                        <div className={style.header_rightBlock_element_title}>
                            {predictBalance.toLocaleString("en")}
                        </div>
                    </div>
                </div>
            </div>

            {
                game != null && gameProcess != null &&
                <div className={style.customPrediction_container}>
                    <Card className={style.customPredictionContainer_card}>
                        <div className={style.customPredictionContainer_card_leftContent}>
                            <img src={game.image ? `${process.env.REACT_APP_FILE_STORAGE_URL}/${game.image}` : ""} alt="PredictionImage" />
                        </div>
                        <div className={style.customPredictionContainer_card_rightContent}>
                            <div className={style.customPredictionContainer_card_rightContent_header}>
                                <div className={style.customPredictionContainer_card_rightContent_header_title}>
                                    <span>
                                        Created by {game.creator_telegram_id == -1
                                            ? <span >System</span>
                                            : (game.last_name != null || game.first_name != null)
                                                ? <span >
                                                    {game.last_name != null && game.last_name}
                                                    &nbsp;
                                                    {game.first_name != null && game.first_name}
                                                </span>
                                                : <span>System</span>
                                        }
                                    </span>
                                </div>
                                <div className={style.customPredictionContainer_card_rightContent_header_buttons}>
                                    <a href={`https://t.me/share/url?url=https://t.me/pixie_project_bot?start=predict${game.id}_${userId}&text=${game.description}`}>
                                        {
                                            theme == "dark"
                                                ? <img src={LinkDarkIcon} alt="LinkIcon" />
                                                : <img src={LinkIcon} alt="LinkIcon" />
                                        }
                                    </a>
                                    {/* {
                                            theme == "dark"
                                                ? <img src={OutlineStarDarkIcon} alt="OutlineStarIcon" />
                                                : <img src={OutlineStarIcon} alt="OutlineStarIcon" />
                                        } */}
                                </div>
                            </div>
                            <div className={style.customPredictionContainer_card_rightContent_title}>
                                {game.name}
                            </div>
                            <div className={style.customPredictionContainer_card_rightContent_info}>
                                <div>
                                    <img src={CoinIcon} alt="PixieDarkIcon" />
                                    <span>{(+(+(gameProcess.prize_pool ?? 0)).toFixed(2)).toLocaleString("en")} Bet</span>
                                </div>
                                <div>
                                    <img src={CalendarIcon} alt="CalendarIcon" />
                                    {game.start_at != null && <span>{DateTime.fromJSDate(game.start_at).toFormat('MMM d, yyyy')}</span>}
                                </div>
                            </div>
                        </div>
                    </Card>

                    <Card className={style.customPredictionContainer_purchaseBlock}>
                        {/* <div className={style.tabControl}>
                        <div
                            className={`${style.tabControl_item}  ${selectTab == Tabs.Buy ? style.tabControl_itemActive : ""}`}
                            onClick={() => setSelectTab(Tabs.Buy)}
                        >
                            Buy
                        </div>
                        <div
                            className={`${style.tabControl_item} ${selectTab == Tabs.Sell ? style.tabControl_itemActive : ""}`}
                            onClick={() => setSelectTab(Tabs.Sell)}
                        >
                            Sell
                        </div>
                    </div> */}

                        <div className={style.customPredictionContainer_purchaseBlock_hint}>
                            <span>Outcome</span>
                            <img src={theme == 'dark' ? InfoDarkIcon : InfoIcon} alt="InfoIcon" />
                        </div>


                        <div className={style.customPredictionContainer_purchaseBlock_actionButtons}>
                            <UpButton
                                title={game.button_name_up}
                                subTitle={gameProcess.coef_up == null ? null : `${(+gameProcess.coef_up).toFixed(2)}x`}
                                flat
                                selected={selectedBetType == BetType.Up} onClick={() => setSelectedBetType(BetType.Up)}
                                disabled={gameProcess.user_in_game || predictionInActive()}
                            />
                            <DownButton
                                title={game.button_name_down}
                                subTitle={gameProcess.coef_down == null ? null : `${(+gameProcess.coef_down).toFixed(2)}x`}
                                selected={selectedBetType == BetType.Down}
                                onClick={() => setSelectedBetType(BetType.Down)}
                                disabled={gameProcess.user_in_game || predictionInActive()}
                            />
                        </div>

                        <div className={style.customPredictionContainer_purchaseBlock_moneyBlock} style={{ opacity: (gameProcess.user_in_game || predictionInActive()) ? 0.6 : 1 }}>
                            <img src={theme == 'dark' ? MinusDarkIcon : MinusIcon} alt="MinusIcon" onClick={minusAmountEvent} />
                            {/* <div>${betPrice.toLocaleString("en")}</div>
                        */}
                            <input
                                type="text"
                                pattern="[0-9]+([,][0-9]{1,2})?"
                                value={betPrice?.toLocaleString("en")}
                                onChange={handleBetPriceChange}
                                disabled={gameProcess.user_in_game || predictionInActive()}
                            />
                            <img src={theme == 'dark' ? PlusDarkIcon : PlusIcon} alt="PlusIcon" onClick={plusAmountEvent} />
                        </div>

                        {/* <div className={style.customPredictionContainer_purchaseBlock_confirmButton} style={{ opacity: gameProcess.in_game == null ? 1 : 0.5 }} onClick={confirmPrediction}> */}
                        <div className={`${style.customPredictionContainer_purchaseBlock_confirmButton} ${(gameProcess.user_in_game || betPrice == 0 || predictionInActive()) ? style.disabled : ""}`} onClick={confirmPrediction}>
                            {predictionInActive()
                                ? <div>
                                    <img src={theme == 'dark' ? LockDarkIcon : LockLightIcon} alt="LockIcon" />
                                    <div>{t("Bets are no longer accepted")} </div>
                                </div>
                                : t("Confirm prediction")
                            }
                        </div>

                        {/* <div className={style.customPredictionContainer_purchaseBlock_statistics}>
                                <div className={style.customPredictionContainer_purchaseBlock_statisticsItem}>
                                    <div className={style.customPredictionContainer_purchaseBlock_statisticsItemTitle}
                                    >
                                        Avg price
                                    </div>
                                    <div className={style.customPredictionContainer_purchaseBlock_statisticsItemSubTitle}
                                    >
                                        0$
                                    </div>
                                </div>

                                <div className={style.divider}></div>

                                <div className={style.customPredictionContainer_purchaseBlock_statisticsItem}>
                                    <div className={style.customPredictionContainer_purchaseBlock_statisticsItemTitle}
                                    >
                                        Shares
                                    </div>
                                    <div className={style.customPredictionContainer_purchaseBlock_statisticsItemSubTitle}
                                    >
                                        0.00
                                    </div>

                                </div>
                                <div className={style.divider}></div>

                                <div className={style.customPredictionContainer_purchaseBlock_statisticsItem}>
                                    <div className={style.customPredictionContainer_purchaseBlock_statisticsItemTitle}
                                    >
                                        Potential return
                                    </div>
                                    <div className={style.customPredictionContainer_purchaseBlock_statisticsItemSubTitle}
                                    >
                                        $0.00 (0/00%)
                                    </div>
                                </div>
                            </div> */}
                    </Card>
                    <div className={style.customPredictionContainer_detailedInformationBlock}>
                        {
                            game.description &&
                            <Accordion title={t('Description and the condition')}>
                                <div className={style.customPredictionContainer_detailedInformationBlock_description}>
                                    {game.description}
                                </div>
                            </Accordion>
                        }

                        {/* <Accordion title='Graph'>
                            <div className={style.customPredictionContainer_detailedInformationBlock_graph}>
                                <span>23 % chance</span>
                                <ReactApexChart options={options} series={series} type="line" height="100%" />
                            </div>
                            <CommentBlock comments={comments} onSendComment={() => { }} addCommentInputColor='dark' />
                        </Accordion> */}
                        <Accordion title={t('Fund Allocation Pie')}>
                            <div className={style.customPredictionContainer_detailedInformationBlock_FundAllocationPie}>
                                {
                                    fundAllocationPie.map((f, i) =>
                                        <div key={i} className={style.customPredictionContainer_detailedInformationBlock_FundAllocationPie_item}>
                                            <div className={style.customPredictionContainer_detailedInformationBlock_FundAllocationPie_item_left}>
                                                {f.persent}%
                                            </div>
                                            <div className={style.customPredictionContainer_detailedInformationBlock_FundAllocationPie_item_right}>
                                                {f.text}
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        </Accordion>
                    </div>
                    <div><Toaster /></div>

                </div>
            }
        </div>
    )
}
