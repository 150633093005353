import ApexCharts from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import style from './fundsAllocationStyle.module.scss';
import { useTheme } from '../../../Context/Predict/themeContext';

interface Statistic {
    percent: number;
    description: string;
    color: string;
}

interface ChartData {
    series: number[];
    options: ApexCharts.ApexOptions;
}

export function FundsAllocationPage() {
    const { theme } = useTheme();

    const statistics: Statistic[] = [
        { percent: 5, description: "Commission to the licence holders", color: theme == 'light' ? '#6cec9f' : '#BB69F5' },
        { percent: 25, description: "Commission to the NFT house owners", color: theme == 'light' ? '#d7ff65' : '#6E65DC' },
        { percent: 35, description: "Commission to the NFT landlords", color: theme == 'light' ? '#69ea69' : '#7A1AAB' },
        { percent: 45, description: "Staking fund", color: theme == 'light' ? '#addd8f' : '#8F86FF' },
        { percent: 15, description: "Charity fund", color: theme == 'light' ? '#d7ff65' : '#DC6EF2' },
        { percent: 70, description: "Commission of the game creator", color: theme == 'light' ? '#B5E627' : '#9567E9' }
    ];


    const chartData: ChartData = {
        series: statistics.map(stat => stat.percent),
        options: {
            chart: {
                type: 'radialBar',
            },

            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '10%'
                    },
                    track: {
                        background: theme == 'light' ? "#585858" : "#382f5a",
                        strokeWidth: '90px',
                    },
                    dataLabels: {
                        name: {
                            show: false,
                        },
                        value: {
                            show: false,
                        },
                    },
                },
            },
            labels: statistics.map(stat => stat.description),
            stroke: {
                lineCap: "round",
            },
            fill: {
                type: 'solid',
                colors: statistics.map(stat => stat.color),
            }
        },
    };

    return (
        <div className={style.fundsAllocationContainer}>
            <div className={style.fundsAllocationContainer_chart}>
                <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="radialBar"
                    height={350}
                />
            </div>
            <div className={style.fundsAllocationContainer_chartValues}>
                {
                    statistics.map((statistic, index) =>
                        <div key={index} className={style.fundsAllocationContainer_chartValue}>
                            <div
                                style={{
                                    width: '6px',
                                    height: '6px',
                                    borderRadius: '50%',
                                    marginRight: '8px',
                                    backgroundColor: statistic.color,
                                }}
                            />
                            <div className={style.fundsAllocationContainer_chartValue_persent}>
                                {statistic.percent}%
                            </div>
                            <div className={style.fundsAllocationContainer_chartValue_description}>
                                {statistic.description}
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
