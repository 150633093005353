import React, { useEffect, useState } from 'react'
import style from './profileStyle.module.scss'
import nft1 from '../../../Resources/Predict/images/nfs/1.png'
import nft2 from '../../../Resources/Predict/images/nfs/2.png'
import nft3 from '../../../Resources/Predict/images/nfs/3.png'
import nft4 from '../../../Resources/Predict/images/nfs/4.png'
import nft5 from '../../../Resources/Predict/images/nfs/5.png'
import nft6 from '../../../Resources/Predict/images/nfs/6.png'
import ethereumIcon from '../../../Resources/Predict/icons/Ethereum.svg'

export interface nft {
    name: string,
    number: string,
    price: number,
    code: number,
    photo: string
}

export function ProfileNftsBlock() {
    const [nfts, setNfts] = useState<nft[]>([]);

    useEffect(() => {
        loadNfts();
    }, [])

    function loadNfts() {
        setNfts([
            { name: "Abstract Pink", number: "abstract #2538", price: 0.906, code: 1800, photo: nft1 },
            { name: "Abstract Pink", number: "abstract #2538", price: 0.906, code: 1800, photo: nft2 },
            { name: "Abstract Pink", number: "abstract #2538", price: 0.906, code: 1800, photo: nft3 },
            { name: "Abstract Pink", number: "abstract #2538", price: 0.906, code: 1800, photo: nft4 },
            { name: "Abstract Pink", number: "abstract #2538", price: 0.906, code: 1800, photo: nft5 },
            { name: "Abstract Pink", number: "abstract #2538", price: 0.906, code: 1800, photo: nft6 },
        ])
    }

    return (
        <div className={style.profileNftsBlock}>
            {
                nfts.map((nft, index) =>
                    <div key={index} className={style.profileNftsBlock_nftCard}>
                        <img src={nft.photo} alt={nft.name} />
                        <div className={style.profileNftsBlock_nftCard_title}>
                            {nft.name}
                        </div>
                        <div className={style.profileNftsBlock_nftCard_subTitle}>
                            {nft.number}
                        </div>
                        <div className={style.profileNftsBlock_nftCard_priceBlock}>
                            <div className={style.profileNftsBlock_nftCard_priceBlock_ethereum}>
                                <img src={ethereumIcon} alt="ethereumIcon" />
                                <span>{nft.price}</span>
                            </div>
                            <div className={style.profileNftsBlock_nftCard_priceBlock_code} >
                                {nft.code}
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
