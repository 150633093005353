import { useEffect } from 'react'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import EditIcon from '../../../Resources/Predict/icons/edit.svg'
import EditDarkIcon from '../../../Resources/Predict/icons/edit_dark.svg'
import starIcon from '../../../Resources/Predict/icons/star.svg'
import style from './profileStyle.module.scss'
import { Card } from '../../../Components/Predict/card/card'
import { useTheme } from '../../../Context/Predict/themeContext'

enum WalletType {
    MetamaskConnected = 1,
}

export function ProfilePage() {
    const { theme } = useTheme();

    const authUser = {
        id: 1,
        avatar: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/1200px-Circle-icons-profile.svg.png",
        name: "s900_WE",
        email: "john.doe@example.com",
        wallet: WalletType,
        _2FAStatus: false,
        level: 3
    }

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const currentLocation = location.pathname;
        if (currentLocation == '/predict/profile' || currentLocation == "/predict/profile/") {
            navigate("/predict/profile/info");
        }
    }, [])

    function editProfilePhoto() {
        let file;

        const input = document.createElement('input');
        input.type = "file";
        input.accept = ".png, .jpg, .jpeg";

        input.addEventListener('change', async (event: Event) => {
            const target = event.target as HTMLInputElement;

            if (target.files && target.files[0]) {
                file = target.files[0];
            }
        });

        input.click();
    }

    return (
        <div className={style.profileContainer}>
            {/* <Card>
                <div className={style.profileContainer_avatarBlock}>
                    <div></div>
                    <div className={style.profileContainer_avatarBlock_avatar}>
                        <div className={style.profileContainer_avatarBlock_avatarImage} >
                            <img src={authUser.avatar} alt="avatar" />
                            <img className={style.profileContainer_avatarBlock_avatarEditIcon} src={theme == 'light' ? EditIcon : EditDarkIcon} alt='editIcon' onClick={editProfilePhoto} />
                        </div>
                    </div>
                    <div className={style.profileContainer_avatarBlockLevelContainer}>
                        <div className={style.profileContainer_avatarBlockLevel}>
                            <img src={starIcon} alt="starIcon" />
                            <span>
                                Level {authUser.level}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={style.profileContainer_profileNavigationBlock}>
                    <NavLink
                        to={"/predict/profile/info"}
                        className={({ isActive }) =>
                            isActive ? `${style.profileContainer_profileNavigationBlock_navItem} ${style.active}` : style.profileContainer_profileNavigationBlock_navItem
                        }
                    >
                        <div>Info</div>
                    </NavLink>
                    <NavLink
                        to={"/predict/profile/balance"}
                        className={({ isActive }) =>
                            isActive ? `${style.profileContainer_profileNavigationBlock_navItem} ${style.active}` : style.profileContainer_profileNavigationBlock_navItem
                        }
                    >
                        <div>Balance</div>
                    </NavLink>
                    <NavLink
                        to={"/predict/profile/balance-history"}
                        className={({ isActive }) =>
                            isActive ? `${style.profileContainer_profileNavigationBlock_navItem} ${style.active}` : style.profileContainer_profileNavigationBlock_navItem
                        }
                    >
                        <div>Balance History</div>
                    </NavLink>
                    <NavLink
                        to={"/predict/profile/game-history"}
                        className={({ isActive }) =>
                            isActive ? `${style.profileContainer_profileNavigationBlock_navItem} ${style.active}` : style.profileContainer_profileNavigationBlock_navItem
                        }
                    >
                        <div>Game History</div>
                    </NavLink>
                    <NavLink
                        to={"/predict/profile/my-stats"}
                        className={({ isActive }) =>
                            isActive ? `${style.profileContainer_profileNavigationBlock_navItem} ${style.active}` : style.profileContainer_profileNavigationBlock_navItem
                        }
                    >
                        <div>My Stats</div>
                    </NavLink>
                    <NavLink
                        to={"/predict/profile/my-nfts"}
                        className={({ isActive }) =>
                            isActive ? `${style.profileContainer_profileNavigationBlock_navItem} ${style.active}` : style.profileContainer_profileNavigationBlock_navItem
                        }
                    >
                        <div>My NFTs</div>
                    </NavLink>
                    <NavLink
                        to={"/predict/profile/referal"}
                        className={({ isActive }) =>
                            isActive ? `${style.profileContainer_profileNavigationBlock_navItem} ${style.active}` : style.profileContainer_profileNavigationBlock_navItem
                        }
                    >
                        <div>Referral</div>
                    </NavLink>
                </div>
            </Card> */}
            <Outlet />
        </div>
    )
}
