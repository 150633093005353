import { useContext, useEffect, useState } from 'react'
import Modal from '../../../../../Components/Predict/modal/modal'
import GameContext from '../../../../../Context/GameContext';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../../Components/Predict/card/card';
import CoinIcon from '../../../../../Resources/Predict/images/coin.svg'
import PartyIcon from '../../../../../Resources/Predict/icons/party.svg'
import style from './resultModalStyle.module.scss';
import { GameProcess } from '../../../../../Domain/Predict/gameProcess';

interface IProps {
    gameProcess: GameProcess
    isOpen: boolean;
    onClose: () => void;
}

export function PredictionResultModal(props: IProps) {
    const { userId } = useContext(GameContext);
    const [shareText, setShareText] = useState<string>("");

    const { t, i18n } = useTranslation();

    useEffect(() => {
        loadShareText();
    }, []);


    function loadShareText() {
        if (props.gameProcess.player_price_bet == null) return;
        if (props.gameProcess.player_price_win == null) return;

        if (i18n.language === 'ru') {
            setShareText(`Я поставил ${Math.round(+props.gameProcess.player_price_bet!)} монет и выиграл ${Math.round(+props.gameProcess.player_price_win!)}! Заходи и играй со мной!`)
        } else if (i18n.language === 'uk') {
            setShareText(`Я поставив ${Math.round(+props.gameProcess.player_price_bet!)} монет і виграв ${Math.round(+props.gameProcess.player_price_win!)}! Заходь і грай зі мною!`)
        } else {
            setShareText(`I bet ${Math.round(+props.gameProcess.player_price_bet!)} coins and won ${Math.round(+props.gameProcess.player_price_win!)}! Come and play with me!`)
        }
    }

    const isWinGame = () => {
        const priceDifference = (props.gameProcess.price_last ?? 0) - (props.gameProcess.price_locked ?? 0);
        const winCombination = priceDifference > 0;
        const isWinGame = priceDifference == 0 ? null : props.gameProcess.in_game == winCombination;

        return isWinGame;
    }

    const priceDifference = (props.gameProcess.price_last ?? 0) - (props.gameProcess.price_locked ?? 0);

    function formatPriceDifference() {
        const difference = priceDifference < 0 ? priceDifference * -1 : priceDifference;

        return (difference < 1 && difference > -1)
            ? difference.toFixed(3)
            : Math.round(difference)
    }

    const isBTC = +props.gameProcess.game_id == 1;

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <Card className={style.resultModalContainer}>
                <div className={`${style.resultModalContainer_title} ${isWinGame() == null ? style.gray : isWinGame() ? style.green : style.red}`}>
                    {
                        isWinGame() == null ? t('Without changes') : isWinGame() ? t('You Won!') : t('You Lose :c')
                    }
                    {isWinGame() == true && <img src={PartyIcon} alt="PartyIcon" />}
                </div>
                <div className={style.resultModalContainer_mainResult}>
                    <img src={CoinIcon} alt="CoinIcon" />
                    <span className={style.resultModalContainer_mainResult_main}>
                        {isWinGame() == null ? " " : isWinGame() ? "+" : "-"}
                        {isWinGame() == null ? "0" : isWinGame() ? (+(+props.gameProcess.player_price_win!).toFixed(2)).toLocaleString('en') : (+(+props.gameProcess.player_price_bet!).toFixed(2)).toLocaleString('en')}
                    </span>
                    {isWinGame() == true && <span className={style.resultModalContainer_mainResult_coeff}>
                        {
                            props.gameProcess.in_game
                                ? props.gameProcess.coef_up != null ? <span>x{(+props.gameProcess.coef_up).toFixed(2)}</span> : null
                                : props.gameProcess.coef_down != null ? <span>x{(+props.gameProcess.coef_down).toFixed(2)}</span> : null
                        }
                    </span>}
                </div>
                <div className={style.resultModalContainer_informationContainer}>
                    <div className={style.resultModalContainer_informationContainer_item}>
                        {
                            priceDifference == 0
                                ? <span> {isBTC ? t('Bitcoin dont rose') : t('Ethereum dont rose')} ${formatPriceDifference()}</span>
                                : priceDifference > 0
                                    ? <span> {isBTC ? t('Bitcoin rose by') : t('Ethereum rose by')} ${formatPriceDifference()}</span>
                                    : <span> {isBTC ? t('Bitcoin fell by') : t('Ethereum fell by')} ${formatPriceDifference()}</span>
                        }
                    </div>
                </div>
                {
                    isWinGame() == null || isWinGame() == false &&
                    <div className={style.resultModalContainer_buttons_button} onClick={props.onClose}>
                        Ok
                    </div>
                }

                {
                    isWinGame() == true &&
                    <div className={style.resultModalContainer_buttons}>
                        <div className={style.resultModalContainer_buttons_button} onClick={props.onClose}>
                            Ok
                        </div>

                        <div className={style.resultModalContainer_buttons_button}>
                            <a className={style.resultModalContainer_replayLink} href={`https://t.me/share/url?url=https://t.me/pixie_project_bot?start=predict${1}_${userId}&text=${shareText}`}>
                                {t('Share result')}
                            </a>
                        </div>
                    </div>
                }
            </Card>
        </Modal>
    )
}
