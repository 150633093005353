import '../App.css';
import { useContext, useEffect, useState } from "react";
import coinImage from "../Resources/images/coin.svg";
import rocketImage from "../Resources/images/rocket.svg";
import GameContext from "../Context/GameContext";
import Loader from "../Components/Loader";
import { useTranslation } from "react-i18next";

import energyIcon from "../Resources/images/improvements/6.png";
import { BoostsProvider } from '../Domain/Boosts/boostsProvider';

export default function Boosts() {
    const { score, energyPerSecond, token, default_max_energy, default_coins_per_tap, updateGame } = useContext(GameContext);

    const [multitapPrice, setMultitapPrice] = useState<number>(0);
    const [energyTotalPrice, setEnergyTotalPrice] = useState<number>(0);
    const [energyPerSecondPrice, setEnergyPerSecondPrice] = useState<number>(0);

    const [isMultitapLoading, setIsMultitapLoading] = useState<boolean>(false);
    const [isEnergyTotalLoading, setIsEnergyTotalLoading] = useState<boolean>(false);
    const [isEnergyPerSecondLoading, setIsEnergyPerSecondLoading] = useState<boolean>(false);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        let price = 1000;
        for (let i = 1; i <= default_coins_per_tap; i++) {
            price = price * 2;
        }
        setMultitapPrice(price);

        price = 1000;
        for (let i = 1; i <= energyPerSecond; i++) {
            price = price * 2;
        }
        setEnergyPerSecondPrice(price);

        price = 1000;
        for (let i = 1; i <= ((default_max_energy / 500) - 1); i++) {
            price = price * 2;
        }
        setEnergyTotalPrice(price);
    }, []);

    async function multitap() {
        if (score < multitapPrice) return;

        setIsMultitapLoading(true);
        const response = await BoostsProvider.buyMultitap(token!);
        if (response.message === 'ok') {
            updateGame({
                coinsPerClick: parseInt(response.coins_per_click),
                score: parseFloat(response.balance),
                default_coins_per_tap: parseInt(response.default_coins_per_click)
            });

            setIsMultitapLoading(false);
            setMultitapPrice(parseInt(response.new_price));
        }
    }

    async function energyTotal() {
        if (score < energyTotalPrice) return;

        setIsEnergyTotalLoading(true);
        const response = await BoostsProvider.buyEnergytotal(token!);
        if (response.message === 'ok') {
            updateGame({
                maxEnergy: parseInt(response.max_energy),
                score: parseFloat(response.balance),
                default_max_energy: parseInt(response.default_max_energy)
            });

            setIsEnergyTotalLoading(false);
            setEnergyTotalPrice(parseInt(response.new_price));
        }
    }

    async function energyPerSecondBuy() {
        if (score < energyPerSecondPrice) return;

        setIsEnergyPerSecondLoading(true);
        const response = await BoostsProvider.buyEnergypersecond(token!);
        if (response.message === 'ok') {
            updateGame({
                score: parseFloat(response.balance),
                energyPerSecond: parseInt(response.energy_per_second)
            });

            setIsEnergyPerSecondLoading(false);
            setEnergyPerSecondPrice(parseInt(response.new_price));
        }
    }

    if (multitapPrice === null || energyTotalPrice === null) return <Loader />;

    return (
        <div className="App">
            <div className="boosts_container">
                <h1 className="boosts_container-name">{t('Boosts')}</h1>
                <div className="boosts_container-column">
                    <button className={"boosts_container-row-button " + ((score < multitapPrice) || isMultitapLoading ? 'disabled' : '')} onClick={multitap}>
                        <img src={rocketImage} alt="" className="boosts_container-row-button-icon" />
                        <div className="boosts_container-row-button-text">
                            <span className="boosts_container-row-button-text-name">Multitap</span>
                            <div className="boosts_container-row-button-text-undername">
                                <span className="boosts_container-row-button-text-undername-coins">
                                    <img src={coinImage} alt="" />
                                    {Math.round(multitapPrice / 1000)}K
                                </span>
                                <span className="boosts_container-row-button-text-undername-lvl">
                                    {Math.round(default_coins_per_tap)} lvl
                                </span>
                            </div>
                        </div>
                    </button>
                    <button className={"boosts_container-row-button " + ((score < energyTotalPrice) || isEnergyTotalLoading ? 'disabled' : '')} onClick={energyTotal}>
                        <img src={energyIcon} alt="" className="boosts_container-row-button-icon" />
                        <div className="boosts_container-row-button-text">
                            <span className="boosts_container-row-button-text-name">Energy limit</span>
                            <div className="boosts_container-row-button-text-undername">
                                <span className="boosts_container-row-button-text-undername-coins">
                                    <img src={coinImage} alt="" />
                                    {Math.round(energyTotalPrice / 1000)}K
                                </span>
                                <span className="boosts_container-row-button-text-undername-lvl">
                                    {Math.round((default_max_energy / 500) - 1)} lvl
                                </span>
                            </div>
                        </div>
                    </button>
                    <button className={"boosts_container-row-button " + ((score < energyPerSecondPrice) || isEnergyPerSecondLoading ? 'disabled' : '')} onClick={energyPerSecondBuy}>
                        <img src={energyIcon} alt="" className="boosts_container-row-button-icon" />
                        <div className="boosts_container-row-button-text">
                            <span className="boosts_container-row-button-text-name">Energy per second</span>
                            <div className="boosts_container-row-button-text-undername">
                                <span className="boosts_container-row-button-text-undername-coins">
                                    <img src={coinImage} alt="" />
                                    {Math.round(energyPerSecondPrice / 1000)}K
                                </span>
                                <span className="boosts_container-row-button-text-undername-lvl">
                                    {energyPerSecond} lvl
                                </span>
                            </div>
                        </div>
                    </button>
                    {/*<button className="boosts_container-row-button">*/}
                    {/*    <img src={rocketImage} alt="" className="boosts_container-row-button-icon"/>*/}
                    {/*    <div className="boosts_container-row-button-text">*/}
                    {/*        <span className="boosts_container-row-button-text-name">Energy recovery per second</span>*/}
                    {/*        <div className="boosts_container-row-button-text-undername">*/}
                    {/*            <span className="boosts_container-row-button-text-undername-coins">*/}
                    {/*                <img src={coinImage} alt=""/>*/}
                    {/*                1K*/}
                    {/*            </span>*/}
                    {/*            <span className="boosts_container-row-button-text-undername-lvl">*/}
                    {/*                7 lvl*/}
                    {/*            </span>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</button>*/}
                    {/*<button className="boosts_container-row-button">*/}
                    {/*    <img src={rocketImage} alt="" className="boosts_container-row-button-icon"/>*/}
                    {/*    <div className="boosts_container-row-button-text">*/}
                    {/*        <span className="boosts_container-row-button-text-name">Max energy</span>*/}
                    {/*        <div className="boosts_container-row-button-text-undername">*/}
                    {/*            <span className="boosts_container-row-button-text-undername-coins">*/}
                    {/*                <img src={coinImage} alt=""/>*/}
                    {/*                1K*/}
                    {/*            </span>*/}
                    {/*            <span className="boosts_container-row-button-text-undername-lvl">*/}
                    {/*                7 lvl*/}
                    {/*            </span>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</button>*/}
                </div>
            </div>
        </div>
    );
}