import '../App.css';
import { useContext, useEffect, useState } from "react";
import coinImage from "../Resources/images/coin.svg"
import horizontalLine from "../Resources/images/horizontal_line.svg"
import Loader from "../Components/Loader";
import GameContext from "../Context/GameContext";
import { useTranslation } from "react-i18next";
import default_skin from "../Resources/images/human-free.png";
import present_image from "../Resources/images/present.svg";
import present_image_dark from "../Resources/images/present_dark.svg";
import present_image_light from "../Resources/images/present_light.svg";
import { skins_images } from '../constants';
import { ReferralsProvider } from '../Domain/Referrals/referralsProvider';
import { useTheme } from '../Context/Predict/themeContext';

export default function Referrals() {
    const [referralsData, setReferralsData] = useState<any[]>([])

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [groupedReferrals, setGroupedReferrals] = useState<{ [key: string]: any[]; } | null>(null);
    const [countReferrals, setCountReferrals] = useState<number>(0);

    const [shareText, setShareText] = useState<string>("");
    const [sumGetCoins, setSumGetCoins] = useState<number>(0);
    const [earnsFromLevels, setEarnsFromLevels] = useState({ 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0 });

    const { updateGame, userId, token } = useContext(GameContext);

    const { clickerTheme } = useTheme();

    const { t, i18n } = useTranslation();

    let referrals_coefs = [
        0,
        30,
        17.5,
        15,
        12.5,
        10,
        7.5,
        5,
        2.5
    ];

    useEffect(() => {
        if (referralsData !== null) {
            setGroupedReferrals(referralsData.reduce((acc, referral) => {
                if (!acc[referral.level]) {
                    acc[referral.level] = [];
                }
                acc[referral.level].push(referral);
                return acc;
            }, {}));
        }
    }, [referralsData]);

    useEffect(() => {
        // if(i18n.language === 'ru'){
        //     setShareText("Играй со мной, стань босом студии и получай токены через аирдроп!%0A\uD83D\uDCB8 +20k монет за вход%0A\uD83D\uDD25 +50k монет, если у тебя есть Telegram Premium")
        // }else if(i18n.language === 'uk'){
        //     setShareText("Грай зі мною, стань босом студії та отримай токени через ейрдроп!%0A\uD83D\uDCB8 +20k монет у якості подарунка%0A\uD83D\uDD25 +50k монет, якщо у тебе Telegram Premium")
        // }else{
        //     setShareText("Play with me, become a studio boss and get tokens via airdrop! %0A\uD83D\uDCB8 +20k coins as a gift%0A\uD83D\uDD25 +50k coins, if you have Telegram Premium")
        // }
        if (i18n.language === 'ru') {
            setShareText("Играй со мной, стань босом студии и получай токены через аирдроп!%0A\uD83D\uDCB8 +1.000.000 монет за вход")
        } else if (i18n.language === 'uk') {
            setShareText("Грай зі мною, стань босом студії та отримай токени через ейрдроп!%0A\uD83D\uDCB8 +1.000.000 монет у якості подарунка")
        } else {
            setShareText("Play with me, become a studio boss and get tokens via airdrop! %0A\uD83D\uDCB8 +1.000.000 coins as a gift")
        }

        loadReferrals();
    }, []);

    async function loadReferrals() {
        setIsLoaded(false);

        const referrals = await ReferralsProvider.getReferrals(userId);

        let new_referrals: any[] = [];
        let total_recieved_can_now = 0;

        if (referrals.length > 500 && referrals.length < 1000) {
            referrals_coefs = [
                0,
                20,
                15,
                10,
                5,
                4.5,
                4,
                3.5,
                2
            ];
        } else if (referrals.length >= 1000 && referrals.length < 1999) {
            referrals_coefs = [
                0,
                15,
                10,
                7.5,
                5,
                2.5,
                2,
                1.5,
                1
            ];
        } else if (referrals.length >= 2000) {
            referrals_coefs = [
                0,
                5,
                4,
                3,
                2.5,
                2,
                1.7,
                1.5,
                1
            ];
        }

        referrals.forEach(item => {
            let new_item: any = {};
            switch (item.level) {
                case 1: new_item.earn_received = item.earn_received_level_1 || 0; break;
                case 2: new_item.earn_received = item.earn_received_level_2 || 0; break;
                case 3: new_item.earn_received = item.earn_received_level_3 || 0; break;
                case 4: new_item.earn_received = item.earn_received_level_4 || 0; break;
                case 5: new_item.earn_received = item.earn_received_level_5 || 0; break;
                case 6: new_item.earn_received = item.earn_received_level_6 || 0; break;
                case 7: new_item.earn_received = item.earn_received_level_7 || 0; break;
                case 8: new_item.earn_received = item.earn_received_level_8 || 0; break;
            }

            new_item.first_name = item.first_name;
            new_item.last_name = item.last_name;
            new_item.username = item.username;
            new_item.level = item.level;
            new_item.referal_id = item.referal_id;
            new_item.total_earn = item.total_earn;
            new_item.skin_id = item.skin_id;

            new_item.can_recieved = (parseFloat(new_item.total_earn) / 100 * referrals_coefs[parseInt(new_item.level)]) - new_item.earn_received;

            if (new_item.can_recieved < 0 || isNaN(new_item.can_recieved)) {
                new_item.can_recieved = 0;
            }

            total_recieved_can_now = total_recieved_can_now + parseFloat(new_item.can_recieved);
            new_referrals.push(new_item);
        });

        setSumGetCoins(total_recieved_can_now);
        setReferralsData(new_referrals);
        setCountReferrals(referrals.length);

        setIsLoaded(true);
    }

    function nicknameFormat(first_name: string | null, last_name: string | null, username: string) {
        let nickname = "";
        if (first_name !== 'None' && first_name !== null) {
            nickname = first_name;
        }
        if (last_name !== 'None' && last_name !== null) {
            if (nickname !== "") {
                nickname = nickname + " " + last_name;
            } else {
                nickname = last_name;
            }
        }

        if (nickname === "") {
            nickname = username;

            if (nickname === "") {
                nickname = "Hidden username";
            }
        }

        return nickname;
    }

    async function getCoinsFromReferrals() {
        setIsLoaded(false);

        if (sumGetCoins < 0) {
            setSumGetCoins(sumGetCoins * (-1))
        }

        let date_now_obj = new Date();
        let date_now_timestamp = date_now_obj.getTime();
        date_now_timestamp = date_now_timestamp / 1000;
        date_now_timestamp = date_now_timestamp;

        const getCoinsFromReferralsResponse = await ReferralsProvider.getCoinsFromReferrals(token!, date_now_timestamp);
        updateGame({
            score: parseFloat(getCoinsFromReferralsResponse.balance)
        })

        const referrals = await ReferralsProvider.getReferrals(userId);
        let new_referrals: any[] = [];
        let total_recieved_can_now = 0;

        if (referrals.length > 500) {
            referrals_coefs = [
                0,
                1.9,
                1.8,
                1.7,
                1.6,
                1.5,
                1.4,
                1.3,
                1.2
            ];
        }

        referrals.forEach(item => {
            let new_item: any = {};

            switch (item.level) {
                case 1: new_item.earn_received = item.earn_received_level_1 || 0; break;
                case 2: new_item.earn_received = item.earn_received_level_2 || 0; break;
                case 3: new_item.earn_received = item.earn_received_level_3 || 0; break;
                case 4: new_item.earn_received = item.earn_received_level_4 || 0; break;
                case 5: new_item.earn_received = item.earn_received_level_5 || 0; break;
                case 6: new_item.earn_received = item.earn_received_level_6 || 0; break;
                case 7: new_item.earn_received = item.earn_received_level_7 || 0; break;
                case 8: new_item.earn_received = item.earn_received_level_8 || 0; break;
            }

            new_item.first_name = item.first_name;
            new_item.last_name = item.last_name;
            new_item.username = item.username;
            new_item.level = item.level;
            new_item.referal_id = item.referal_id;
            new_item.total_earn = item.total_earn;

            new_item.can_recieved = (parseFloat(new_item.total_earn) / 100 * referrals_coefs[parseInt(new_item.level)]) - new_item.earn_received;

            if (new_item.can_recieved < 0 || isNaN(new_item.can_recieved)) {
                new_item.can_recieved = 0;
            }

            total_recieved_can_now = total_recieved_can_now + parseFloat(new_item.can_recieved);

            new_referrals.push(new_item);
        });

        setSumGetCoins(total_recieved_can_now);
        setReferralsData(new_referrals);
        setCountReferrals(referrals.length);

        setIsLoaded(true);
    }

    if (!isLoaded) return <Loader />;

    return (
        <div className="App">
            <div className="referrals_container">
                <a href={"https://t.me/share/url?url=https://t.me/pixie_project_bot?start=" + userId + "&text=" + shareText} className="referrals_task">
                    <img src={clickerTheme == null ? present_image : clickerTheme == 'dark' ? present_image_dark : present_image_light} alt="" />
                    <div className="referrals_task-text">
                        <span className="referrals_task-text-name">{t('Invite friends task')}</span>
                        <span className="referrals_task-text-undername">{t('Invite your friends and get both 20k coins each. Or 50k each for a friend who has Telegram premium')}</span>
                    </div>
                </a>

                <button className="referrals_get_coins" onClick={getCoinsFromReferrals}>Claim {sumGetCoins < 0 || !sumGetCoins ? '0' : Math.round(sumGetCoins).toLocaleString('en')} coins</button>
                <h1 className="referrals_container-name">{t('Referrals list')} {countReferrals > 0 ? <span className="referrals-counter-all">Total {countReferrals}</span> : ''}</h1>
                <div className="referrals_container-list">
                    {
                        countReferrals > 0
                            ? Object.keys(groupedReferrals!).map(level => (
                                <div key={level}>
                                    <span className="referrals_container-list-levelname">{t('Level')} {level} <span className="percents_for_referals_text">
                                        (+{referrals_coefs[parseInt(level)]}%) <span className="referrals-counter-all">({groupedReferrals![level].length} people)</span>
                                    </span>:</span>
                                    <div className="referrals_container-list-items">
                                        {
                                            groupedReferrals![level].length > 30
                                                ? <span className="so-more-referrals-count">
                                                    {t('More than')} {Math.round(groupedReferrals![level].length / 10) * 10}...
                                                    {/*<span className="referrals-get-coins-more">*/}
                                                    {/*    <img src={coinImage} alt="" className="referrals_container-list-items-item-info-coins-image"/>+{parseInt(earnsFromLevels[level]).toLocaleString('en')}*/}
                                                    {/*</span>*/}
                                                </span>
                                                : groupedReferrals![level].map((referral, index) => (
                                                    <div key={index}>
                                                        <div className="referrals_container-list-items-item" key={referral.referal_id}>
                                                            {/*Referal ID: {referral.referal_id}, Inviter ID: {referral.inviter_id}, Username: {referral.username || 'N/A'}*/}

                                                            {/*<img src={referral.avatar_url || avatarImage} alt="" className="referrals_container-list-items-item-image"/>*/}
                                                            {/*<img src={pixieImages[referral.referal_id % 10]} alt="" className="referrals_container-list-items-item-image"/>*/}
                                                            <div className="referrals_container-list-items-item-image-container">
                                                                <img src={referral.skin_id ? skins_images[referral.skin_id - 1] : default_skin} alt="" className="referrals_container-list-items-item-image" />
                                                            </div>
                                                            <div className="referrals_container-list-items-item-info">
                                                                <span className="referrals_container-list-items-item-info-name">
                                                                    {nicknameFormat(referral.first_name, referral.last_name, referral.username)}
                                                                </span>
                                                                <span className="referrals_container-list-items-item-info-coins">
                                                                    <img src={coinImage} alt="" className="referrals_container-list-items-item-info-coins-image" />
                                                                    {/*+{parseInt((parseInt(referral.total_earn) / 100) * referrals_coefs[parseInt(level)]) || 0}*/}
                                                                    +{parseInt(referral.can_recieved) >= 0 ? parseInt(referral.can_recieved).toLocaleString('en') : 0}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <img src={horizontalLine} alt="" className="referrals_horizontal_line" />
                                                    </div>
                                                ))
                                        }
                                    </div>
                                </div>
                            ))
                            : <p className="referrals_empty">{t('The list of referrals is empty :c')}</p>
                    }
                </div>

                <div className="referrals_share_container">
                    <a href={"https://t.me/share/url?url=https://t.me/pixie_project_bot?start=" + userId + "&text=" + shareText} className="referrals_container_share">
                        {t('Invite friends')}
                    </a>
                </div>
            </div>
        </div>
    );
}
