export class Game {
    constructor(
        public id: number,
        public name: string,
        public created_at: Date,
        public start_at: Date | null,
        public end_at: Date | null,
        public image: string | null,
        public category_id: number,
        public ismoderated: boolean,
        public description: string,
        public button_name_up: string,
        public button_name_down: string,
        public creator_telegram_id: number | null,
        public last_name: string | null,
        public first_name: string | null,
        public coef_up: number | null,
        public coef_down: number | null,
    ) { }
}

export function mapToGame(data: any) {
    const start_at = data.start_at == null ? null : new Date(data.start_at);
    const end_at = data.end_at == null ? null : new Date(data.end_at);

    return new Game(
        data.id,
        data.name,
        new Date(data.created_at),
        start_at,
        end_at,
        data.image,
        data.category_id,
        data.ismoderated,
        data.description,
        data.button_name_up,
        data.button_name_down,
        data.creator_telegram_id,
        data.last_name == "None" ? null : data.last_name,
        data.first_name == "None" ? null : data.first_name,
        data.coef_up == null ? null : parseFloat(data.coef_up),
        data.coef_down == null ? null : parseFloat(data.coef_down),
    );
}