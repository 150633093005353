import '../App.css';
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import coinImage from "../Resources/images/coin.svg"
import menu_predict_light from '../Resources/images/menu/new/predict_light.svg';
import menu_predict_dark from '../Resources/images/menu/new/predict_dark.svg';
import Loader from "../Components/Loader";
import GameContext from "../Context/GameContext";
import { useTranslation } from "react-i18next";
import default_skin from "../Resources/images/human-free.png";
import pencilIcon from "../Resources/images/pencil.svg";
import { skins_images } from '../constants';
import { FamilyProvider } from '../Domain/Family/familyProvider';
import { EventProvider } from '../Domain/Event/eventProvider';
import { Family } from '../Domain/Family/family';
import { Award } from '../Domain/Family/award';
import { User } from '../Domain/User/user';
import { useTheme } from '../Context/Predict/themeContext';

export default function Families() {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const [families, setFamilies] = useState<Family[]>([]);
    const [familiesObject, setFamiliesObject] = useState<Family[]>([]);
    const [currentFamily, setCurrentFamily] = useState<Family | null>(null);
    const [currentFamilyUsers, setCurrentFamilyUsers] = useState<User[]>([]);

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showPopupEdit, setShowPopupEdit] = useState<boolean>(false);
    const [showPopupCreate, setShowPopupCreate] = useState<boolean>(false);
    const [showPopupCoinsGame, setShowPopupCoinsGame] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [name, setName] = useState<string | null>(null);
    const [description, setDescription] = useState<string | null>(null);
    const [isCreateLoader, setIsCreateLoader] = useState<boolean>(false);
    const [errorFileSize, setErrorFileSize] = useState<string>("");
    const [nameSearchFamilies, setNameSearchFamilies] = useState<string>("");

    const [awards, setAwards] = useState<Award[]>([]);
    const [awardClaimLoader, setAwardClaimLoader] = useState<boolean>(false);

    const [timeToPlayAgainCoinGame, setTimeToPlayAgainCoinGame] = useState<number>(0);

    const [coinGames, setCoinGames] = useState<any[]>([]);
    const [totalUserCoinCount, setTotalUserCoinCount] = useState<number>(0);

    const { userId, family_id, updateGame, score, token, eventTimeEndSecond } = useContext(GameContext);

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { theme } = useTheme();

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeToPlayAgainCoinGame(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeToPlayAgainCoinGame]);

    useEffect(() => {
        loadFamilies();
    }, [])

    async function loadFamilies() {
        const getResult = await FamilyProvider.getList(userId);
        setFamilies(getResult.families);
        setFamiliesObject(getResult.families);

        setIsLoaded(true);
        setAwards(getResult.awards);
    }

    function showPopupCreateFamily() {
        if (family_id !== null) {
            return false;
        }
        setShowPopupCreate(true);
    }

    async function viewFamilyMore(family: Family) {
        setShowPopup(true);

        const users = await FamilyProvider.getUsers(family.id);
        setCurrentFamilyUsers(users);
        setCurrentFamily(family);
    }

    function closePopup() {
        setCurrentFamilyUsers([]);
        setCurrentFamily(null);
        setShowPopup(false);
    }

    function editPopup() {
        setCurrentFamilyUsers([]);
        setShowPopup(false);

        if (currentFamily?.creator_id === userId) {
            setName(currentFamily.name);
            setDescription(currentFamily.description);
            setShowPopupEdit(true);
        }
    }

    function closePopupCreate() {
        setShowPopupCreate(false);
    }

    function closePopupEdit() {
        setShowPopupEdit(false);
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFile(event.target.files == null ? null : event.target.files[0]);
    };

    async function handleSubmit() {
        if (!description || !name) return false;
        if (score < 250000000) return false;

        // Проверка, если файл существует и его размер превышает 2MB
        if (file && file.size > (2097152 / 2)) {
            setErrorFileSize("File size should not exceed 1MB")

            setTimeout(function () {
                setErrorFileSize("");
            }, 3000);
            return false;
        }

        setIsCreateLoader(true);
        try {
            const response = await FamilyProvider.create(file!, name, description, token!);
            if (response.message === 'ok') {
                updateGame({
                    score: parseFloat(response.balance),
                    family_id: parseInt(response.family_id)
                });

                setShowPopupCreate(false);
                setIsCreateLoader(false);

                loadFamilies();
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Upload failed');
        }
    };

    async function handleSubmitEdit() {
        if (currentFamily?.creator_id !== userId) return false;
        if (!description || !name) return false;
        if (score < 125000000) return false;

        // Проверка, если файл существует и его размер превышает 2MB
        if (file && file.size > (2097152 / 2)) {
            setErrorFileSize("File size should not exceed 1MB")

            setTimeout(function () {
                setErrorFileSize("");
            }, 3000);
            return false;
        }

        setIsCreateLoader(true);

        try {
            const response = await FamilyProvider.edit(file!, name, description, token!);
            if (response.message === 'ok') {
                updateGame({
                    score: parseFloat(response.balance),
                    family_id: parseInt(response.family_id)
                });

                setShowPopupEdit(false);
                setIsCreateLoader(false);
                loadFamilies();

            } else if (response.message === 'error small balance') {
                setIsCreateLoader(false);
                setErrorFileSize("Need more money")

                setTimeout(function () {
                    setErrorFileSize("");
                }, 3000);
            } else if (response.message === 'error name used') {
                setIsCreateLoader(false);
                setErrorFileSize("Name already in use")

                setTimeout(function () {
                    setErrorFileSize("");
                }, 3000);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Upload failed');
        }
    };

    function nicknameFormat(first_name: string | null, last_name: string | null, username: string) {
        let nickname = "";
        if (first_name !== 'None' && first_name !== null) {
            nickname = first_name;
        }
        if (last_name !== 'None' && last_name !== null) {
            if (nickname !== "") {
                nickname = nickname + " " + last_name;
            } else {
                nickname = last_name;
            }
        }

        if (nickname === "") {
            nickname = username;

            if (nickname === "") {
                nickname = "Hidden username";
            }
        }

        if (nickname.length > 20) {
            nickname = nickname.slice(0, 20);
            nickname = nickname + "...";
        }
        return nickname;
    }

    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        const inputText = event.target.value;
        // Устанавливаем текст только если он меньше или равен 30 символам
        if (inputText.length <= 20) {
            setName(inputText);
        }
    }
    function handleDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
        const inputText = event.target.value;
        // Устанавливаем текст только если он меньше или равен 30 символам
        if (inputText.length <= 50) {
            setDescription(inputText);
        }
    }

    async function leaveFamily(family: Family) {
        if (family_id !== family.id) return;

        setCurrentFamilyUsers([]);

        const response = await FamilyProvider.leave(family.id, token!)
        if (response.message === 'ok') {
            updateGame({
                family_id: null
            });

            const users = await FamilyProvider.getUsers(family.id);
            setCurrentFamilyUsers(users);
            setCurrentFamily(family);

            loadFamilies();
        } else {
            console.log("error");
        }
    }

    async function joinFamily(family: Family) {
        if (family_id !== null) return;

        setCurrentFamilyUsers([]);

        const response = await FamilyProvider.join(family.id, userId)
        if (response.message === 'ok') {
            updateGame({
                family_id: family.id
            });

            const users = await FamilyProvider.getUsers(family.id);
            setCurrentFamilyUsers(users);
            setCurrentFamily(family);

            loadFamilies();
        } else {
            console.log("error");
        }
    }

    function searchFamiliesSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setFamilies(familiesObject.filter(item => item.name.toLowerCase().includes(nameSearchFamilies.toLowerCase())));
    }
    function searchFamilies() {
        setFamilies(familiesObject.filter(item => item.name.toLowerCase().includes(nameSearchFamilies.toLowerCase())));
    }

    async function eventReward(awardUuid: string) {
        setAwardClaimLoader(true);

        const response = await FamilyProvider.getAward(awardUuid, token!);
        if (response.message === 'ok') {
            updateGame({
                score: parseFloat(response.balance)
            });
            setAwards(avards => avards.filter(a => a.uuid != awardUuid));
            setAwardClaimLoader(false);
        }
    }

    function eventEndTime(seconds: number) {
        // Определение количества часов, минут и секунд
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        // Добавление ведущих нулей, если необходимо
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

        // Формирование итоговой строки
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    function clickCountParseFormat(score: number) {
        if (score > 1000) {
            return score / 1000 + 'K'
        } else if (score > 1000000) {
            return score / 1000000 + 'KK'
        } else if (score > 1000000000) {
            return score / 1000000000 + 'KKK'
        } else {
            return score + '';
        }
    }

    function formatTime(seconds: number) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    async function showCoinGamesPopup() {
        setShowPopupCoinsGame(true);

        setIsCreateLoader(true);
        const response = await EventProvider.getDropCoinsResultList(token!);
        if (response.status == "ok") {
            const coinGames: any[] = response.results ?? [];
            setCoinGames(coinGames);

            const totalUserCoinCount = coinGames.reduce((total, current) => {
                return total + (current.score ?? 0);
            }, 0);

            setTotalUserCoinCount(totalUserCoinCount);

            const lastGame = coinGames.length == 0 ? null : coinGames.reduce((latest, current) => {
                const latestDate = new Date(latest.created_at);
                const currentDate = new Date(current.created_at);
                return currentDate > latestDate ? current : latest;
            }, coinGames[0]);


            let secondToStartGameAgain = 0;
            if (lastGame != null) {
                const lastGameDate = new Date(lastGame.created_at);
                const now = new Date(response.now_datetime);

                const fourHoursLater = new Date(lastGameDate.getTime() + 4 * 60 * 60 * 1000);

                const timeRemaining = fourHoursLater.getTime() - now.getTime();
                secondToStartGameAgain = Math.max(Math.floor(timeRemaining / 1000), 0);
            }

            setTimeToPlayAgainCoinGame(secondToStartGameAgain);
        }
        else {
            console.log("error")
        }

        setIsCreateLoader(false);
    }

    function foramtDate(date: Date) {
        const day = date.getDate();
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const month = monthNames[date.getMonth()];
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day} ${month} ${hours}:${minutes}`;
    }

    if (!isLoaded) return <Loader />;

    return (
        <div className="App">
            {
                awards.length != 0 &&
                <>
                    <span className="award_event_name">{t('EVENT REWARD')}</span>
                    {
                        awards.map((award, index) =>
                            <div className="families_events_award" onClick={() => eventReward(award.uuid)} key={index}>
                                <div className="families_events_award-value">
                                    <img src={coinImage} alt="" />
                                    {(award.coins).toLocaleString('en')}
                                </div>
                                <span className="families_events_award-claim">
                                    {awardClaimLoader ? <Loader /> : 'CLAIM'}
                                </span>
                            </div>
                        )
                    }
                </>
            }
            <div className="families-header">
                <h1 className="families-name-page">Families</h1>
                <button className={"families-create " + (family_id === null ? '' : 'disabled')} onClick={() => { showPopupCreateFamily() }}>Create family</button>
            </div>
            {
                (eventTimeEndSecond && eventTimeEndSecond > 0) &&
                <div className="families-header timer">
                    <span className="coin-timer">{eventTimeEndSecond > 0 ? eventEndTime(eventTimeEndSecond) : ''}</span>
                </div>
            }
            {
                showPopupCreate &&
                <div className="family-popup">
                    <div className="family-popup-content">
                        <>
                            {isCreateLoader ?
                                <Loader />
                                :
                                <>
                                    <button className="family-popup-content-users-item-button-close" onClick={() => { closePopupCreate() }}>✕</button>

                                    <h3 className="family-popup-content-name">Create family</h3>

                                    <div className="family-popup-content-form-group">
                                        <label htmlFor="name">Name</label>
                                        <input type="text" id="name" value={name ?? ""} onChange={handleNameChange} />
                                    </div>

                                    <div className="family-popup-content-form-group">
                                        <label htmlFor="description">Description</label>
                                        <input type="text" id="description" value={description ?? ""} onChange={handleDescriptionChange} />
                                    </div>

                                    <div className="family-popup-content-form-group">
                                        <label htmlFor="file">Image</label>
                                        <input type="file" id="file" onChange={handleFileChange} />
                                    </div>

                                    <div className="create-family-price">
                                        <img src={coinImage} alt="" />
                                        250,000,000
                                    </div>

                                    <p className="error-file-size">{errorFileSize}</p>

                                    <button className={"family-popup-content-create " + ((score < 250000000 ? 'disabled' : ''))} onClick={handleSubmit}>Create</button>
                                </>
                            }
                        </>
                    </div>
                    <div className="family-popup-overlay" onClick={() => { closePopupCreate() }}></div>
                </div>
            }


            {
                showPopupEdit && currentFamily &&
                <div className="family-popup">
                    <div className="family-popup-content">
                        <>
                            {
                                isCreateLoader
                                    ? <Loader />
                                    :
                                    <>
                                        <button className="family-popup-content-users-item-button-close" onClick={() => { closePopupEdit() }}>✕</button>

                                        <h3 className="family-popup-content-name">Edit family</h3>

                                        <div className="family-popup-content-form-group">
                                            <label htmlFor="name">Name</label>
                                            <input type="text" id="name" value={name ?? ""} onChange={handleNameChange} />
                                        </div>

                                        <div className="family-popup-content-form-group">
                                            <label htmlFor="description">Description</label>
                                            <input type="text" id="description" value={description ?? ""} onChange={handleDescriptionChange} />
                                        </div>


                                        <div className="family-popup-content-form-group edit-family">
                                            <label htmlFor="file">
                                                {currentFamily.image !== null && currentFamily.image !== '' ?
                                                    <img src={"https://api-v1-production.pixie-game.com/storage/" + currentFamily.image} alt="" className="families_list-item-image" />
                                                    :
                                                    <img src={coinImage} alt="" className="families_list-item-image" />
                                                }
                                            </label>
                                            <input type="file" id="file" onChange={handleFileChange} />
                                        </div>

                                        <div className="create-family-price">
                                            <img src={coinImage} alt="" />
                                            125,000,000
                                        </div>

                                        <p className="error-file-size">{errorFileSize}</p>

                                        <button className={"family-popup-content-create " + ((score < 125000000 ? 'disabled' : ''))} onClick={handleSubmitEdit}>Edit</button>
                                    </>
                            }
                        </>
                    </div>
                    <div className="family-popup-overlay" onClick={() => { closePopupCreate() }}></div>
                </div>
            }

            {
                showPopup && currentFamily &&
                <div className="family-popup">
                    <div className="family-popup-content">
                        {
                            currentFamilyUsers.length > 0 ?
                                <>
                                    <button className="family-popup-content-users-item-button-close" onClick={() => { closePopup() }}>✕</button>
                                    {
                                        (currentFamily.id === family_id && currentFamily.creator_id === userId) &&
                                        <button className="family-popup-content-users-item-button-edit" onClick={() => { editPopup() }}>
                                            <img src={pencilIcon} alt="" />
                                        </button>
                                    }

                                    <div className="family-popup-content-header">
                                        <h1 className="family-popup-content-name">
                                            {currentFamily.name}
                                        </h1>
                                        <span className="family-popup-content-header-level">
                                            level {currentFamily.level}
                                        </span>
                                    </div>
                                    <h2 className="family-popup-content-description">
                                        {currentFamily.description}
                                    </h2>

                                    {
                                        currentFamily.id === family_id &&
                                        <div className="family-buttons-row">
                                            <button className="family-buttons-row-button donate">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10" stroke="#171717" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12 18.5C13.3807 18.5 14.5 17.3807 14.5 16C14.5 14.6193 13.3807 13.5 12 13.5C10.6193 13.5 9.5 14.6193 9.5 16C9.5 17.3807 10.6193 18.5 12 18.5Z" stroke="#171717" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M17 22H7C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22Z" stroke="#171717" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>

                                                Donate
                                            </button>
                                            {
                                                currentFamily.creator_id != userId &&
                                                <button className="family-buttons-row-button leave" onClick={() => { leaveFamily(currentFamily) }}>
                                                    Leave
                                                </button>
                                            }
                                            {/* {currentFamily.creator_id === userId ? '' :
                                            <button className="family-buttons-row-button donate">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10" stroke="#171717" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12 18.5C13.3807 18.5 14.5 17.3807 14.5 16C14.5 14.6193 13.3807 13.5 12 13.5C10.6193 13.5 9.5 14.6193 9.5 16C9.5 17.3807 10.6193 18.5 12 18.5Z" stroke="#171717" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M17 22H7C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22Z" stroke="#171717" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>

                                                Leave
                                            </button>
                                        } */}
                                        </div>
                                    }

                                    {
                                        family_id === null &&
                                        <div className="family-buttons-row">
                                            <button className="family-buttons-row-button join" onClick={() => joinFamily(currentFamily)}>Join</button>
                                        </div>
                                    }

                                    {/*{family_id === null ?*/}
                                    {/*    <div className="family-buttons-row">*/}
                                    {/*        <button className="family-buttons-row-button join">*/}
                                    {/*            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*                <path d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10" stroke="#171717" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>*/}
                                    {/*                <path d="M12 18.5C13.3807 18.5 14.5 17.3807 14.5 16C14.5 14.6193 13.3807 13.5 12 13.5C10.6193 13.5 9.5 14.6193 9.5 16C9.5 17.3807 10.6193 18.5 12 18.5Z" stroke="#171717" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>*/}
                                    {/*                <path d="M17 22H7C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22Z" stroke="#171717" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>*/}
                                    {/*            </svg>*/}

                                    {/*            Join*/}
                                    {/*        </button>*/}
                                    {/*    </div>*/}
                                    {/*:*/}
                                    {/*    ''*/}
                                    {/*}*/}


                                    <div className="family-popup-content-users-list">
                                        <span className="family-popup-content-users-count">{currentFamily.users_count.toLocaleString()} users</span>
                                        {currentFamilyUsers.map((user, i) => (
                                            <div className={"family-popup-content-users-item " + (user.user_id === userId ? 'selected' : '')} key={i}>
                                                <div className="family-popup-content-users-item-avatar-container">
                                                    <img src={user.current_skin_id ? skins_images[user.current_skin_id - 1] : default_skin} alt="avatar" className="family-popup-content-users-item-avatar" />
                                                </div>
                                                <div className="family-popup-content-users-item-content">
                                                    <span className="family-popup-content-users-item-name">{nicknameFormat(user.first_name, user.last_name, user.username)}</span>
                                                    <span className="family-popup-content-users-item-content-totalearn">
                                                        {/*+{user.event_clicker_count} <img src={coinImage} alt=""/>*/}



                                                        {/* <img src={coinImage} alt="" />
                                                        {user.total_earn ? Math.round(user.total_earn).toLocaleString('en') : 0} */}


                                                        <img src={theme == 'light' ? menu_predict_light : menu_predict_dark} alt="" />
                                                        {(user.bets_count).toLocaleString('en')}


                                                        {/* {user.drop_coins_sum ? parseInt(user.drop_coins_sum).toLocaleString('en') : 0} */}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                                :
                                <Loader />
                        }

                    </div>
                    <div className="family-popup-overlay" onClick={() => { closePopup() }}></div>
                </div>
            }

            {/*<button className="family-create" onClick={()=>{showPopupCreateFamily()}}>+</button>*/}

            {/* <div className='families_coins-game'>
                <button onClick={showCoinGamesPopup}>Coins Game</button>
            </div>

            {
                showPopupCoinsGame &&
                <div className="family-popup">
                    <div className="family-popup-content">
                        {isCreateLoader
                            ? <Loader />
                            :
                            <>
                                <button
                                    className="family-popup-content-users-item-button-close"
                                    onClick={() => { setShowPopupCoinsGame(false) }}
                                >
                                    ✕
                                </button>

                                <h3 className="family-popup-content-name">Coins Game</h3>

                                <div className='family-popup-content-scroll-container'>
                                    <div className="family-popup-content-text-container">
                                        <p>
                                            In the game, your goal is to earn points by clicking on falling coins. Each click increases your score, which accumulates as you play.
                                        </p>

                                        <p>
                                            How to play:

                                            Click on the coins to earn points.
                                            All your points are summed up, and you can see your current score.
                                            Compete with other families to score as many points as possible.
                                        </p>

                                        <p>
                                            The family with the highest score wins. Join the game, show off your skills, and strive for victory!
                                        </p>
                                    </div>

                                    <div className='family-popup-content-coin-image'>
                                        <img src={coinImage} alt='coinImage' />
                                    </div>

                                    <div className='family-popup-content-play-container'>
                                        <button
                                            className="family-popup-content-play-button"
                                            disabled={
                                                timeToPlayAgainCoinGame != 0
                                            }
                                            onClick={() => navigate("/family-game")}
                                        >
                                            PLAY
                                        </button>
                                        {
                                            timeToPlayAgainCoinGame != 0 &&
                                            <div className="family-popup-content-play-time">{formatTime(timeToPlayAgainCoinGame)}</div>
                                        }
                                    </div>

                                    <div className='family-popup-content-game-results'>
                                        <p>Total results: {totalUserCoinCount}</p>

                                        <div className='family-popup-content-game-results-list'>
                                            {coinGames.map((coinGame, index) =>
                                                <div key={index} className='family-popup-content-game-results-list-item'>
                                                    <div className='family-popup-content-game-results-list-item_amount'>
                                                        <img src={coinImage} alt="" />
                                                        {coinGame.score ?? 0}
                                                    </div>
                                                    <div className='family-popup-content-game-results-list-item_date'>
                                                        {foramtDate(new Date(coinGame.created_at))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className="family-popup-overlay" onClick={() => { closePopupCreate() }}></div>
                </div>
            } */}

            <form className="families_list-search-form" onSubmit={searchFamiliesSubmit}>
                <input type="text" placeholder="Name or code" value={nameSearchFamilies} onChange={(e) => { setNameSearchFamilies(e.target.value); }} />
                <button onClick={searchFamilies}>Search</button>
            </form>

            <div className="families_list">
                {families.map((family, i) => (
                    <div key={i} className={"families_list-item " + (family.user_id_in_family === userId ? "selected" : "")} onClick={() => { viewFamilyMore(family) }}>
                        {family.image !== null && family.image !== '' ?
                            <img src={"https://api-v1-production.pixie-game.com/storage/" + family.image} loading="lazy" alt="" className="families_list-item-image" />
                            :
                            <img src={coinImage} alt="" className="families_list-item-image" />
                        }

                        <div className="families_list-item-content">
                            <div className="families_list-item-header">
                                <span className="families_list-item-name">{family.name}</span>
                                {/* <span className="families_list-item-level">Level {family.level}</span> */}
                                {/* <span className="families_list-item-drop-coins-score">
                                    <img src={coinImage} alt="" />
                                    {family.drop_coins_family_score ? parseInt(family.drop_coins_family_score).toLocaleString('en') : 0}
                                </span> */}
                                {/*<span className="families_list-item-level">+{family.invite_users} players</span>*/}
                                {/* <span className="families_list-item-level">Score: {clickCountParseFormat(family.clicks_count)}</span> */}


                                <span className="families_list-item-drop-coins-score">
                                    <img src={theme == 'light' ? menu_predict_light : menu_predict_dark} alt="" />
                                    {(family.bets_count).toLocaleString('en')}
                                </span>

                            </div>
                            <div className="families_list-item-bottom-text">
                                <span className="families_list-item-creator">{nicknameFormat(family.first_name, family.last_name, family.username)}</span>
                                <span className="families_list-item-counter-users">{family.users_count.toLocaleString()} users</span>
                            </div>
                        </div>
                    </div>
                ))}

            </div>
        </div>
    );
}
