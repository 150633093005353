import ReactDOM from 'react-dom/client';
import { I18nextProvider } from "react-i18next";
import App from './App';
import { ThemeProvider } from './Context/Predict/themeContext';
import i18n from './i18n';
import './index.css';
import GameProvider from "./Providers/GameProvider";
import { mockTelegramEnv, parseInitData } from '@telegram-apps/sdk-react';

// const initDataRaw = new URLSearchParams([
//   ['user', JSON.stringify({
//     id: 875591451,
//     first_name: 'Andrew',
//     last_name: 'Rogue',
//     username: 'rogue',
//     language_code: 'en',
//     is_premium: true,
//     allows_write_to_pm: true,
//   })],
//   ['hash', '89d6079ad6762351f38c6dbbc41bb53048019256a9443988af7a48bcad16ba31'],
//   ['auth_date', '1716922846'],
//   ['start_param', 'debug'],
//   ['chat_type', 'sender'],
//   ['chat_instance', '8428209589180549439'],
// ]).toString();

// mockTelegramEnv({
//   themeParams: {
//     accentTextColor: '#6ab2f2',
//     bgColor: '#17212b',
//     buttonColor: '#5288c1',
//     buttonTextColor: '#ffffff',
//     destructiveTextColor: '#ec3942',
//     headerBgColor: '#17212b',
//     hintColor: '#708499',
//     linkColor: '#6ab3f3',
//     secondaryBgColor: '#232e3c',
//     sectionBgColor: '#17212b',
//     sectionHeaderTextColor: '#6ab3f3',
//     subtitleTextColor: '#708499',
//     textColor: '#f5f5f5',
//   },
//   initData: parseInitData(initDataRaw),
//   initDataRaw,
//   version: '7.8',
//   platform: 'tdesktop',
// });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <I18nextProvider i18n={i18n}>
    <GameProvider>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </GameProvider>
  </I18nextProvider>
);
