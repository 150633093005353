import { useEffect, useState } from 'react';
import { Card } from '../../../Components/Predict/card/card';
import CalendarIcon from '../../../Resources/Predict/icons/calendar.svg';
import CalendarDarkIcon from '../../../Resources/Predict/icons/calendar_dark.svg';
import PixIcon from '../../../Resources/Predict/icons/prizePool.svg';
import TopUpIcon from '../../../Resources/Predict/icons/topUp.svg';
import TopUpDarkIcon from '../../../Resources/Predict/icons/topUp_dark.svg';
import style from './profileStyle.module.scss';
import { useTheme } from '../../../Context/Predict/themeContext';

interface ITransaction {
    source: string;
    date: Date;
    balance: number;
    amount: number;
}

export function ProfileBalanceHistoryBlock() {
    const [transactions, setTransactions] = useState<ITransaction[]>([]);
    const { theme } = useTheme();

    useEffect(() => {
        loadTransactions();
    }, [])

    function loadTransactions() {
        setTransactions([
            { source: "Contest Earnings", date: new Date("2024.06.01"), balance: 567.89, amount: 100 },
            { source: "Referral Bonus", date: new Date("2024.06.02"), balance: 567.89, amount: 300 },
            { source: "Contest Earnings", date: new Date("2024.06.03"), balance: 567.89, amount: 200 },
        ])
    }

    return (
        <div className={style.profileBalancaHistoryBlock}>
            {
                transactions.map((transaction, index) =>
                    <Card key={index} className={style.profileBalancaHistoryBlock_transaction}>
                        <div className={style.profileBalancaHistoryBlock_transactionLeft}>
                            <img src={theme == 'dark' ? TopUpDarkIcon : TopUpIcon} alt="topUpIcon" />
                            <div className={style.profileBalancaHistoryBlock_transactionLeft_information}>
                                <div>{transaction.source}</div>
                                <div className={style.profileBalancaHistoryBlock_transactionLeft_information_date}>
                                    <img src={theme == 'light' ? CalendarIcon : CalendarDarkIcon} alt="calendarIcon" />
                                    <span>{transaction.date.toLocaleDateString()}</span>
                                </div>
                            </div>
                        </div>
                        <div className={style.profileBalancaHistoryBlock_transactionRight}>
                            <div className={style.profileBalancaHistoryBlock_transactionRight_amount}>
                                <img src={PixIcon} alt="pixIcon" />
                                <span>+{transaction.amount} PIX</span>
                            </div>
                            <div className={style.profileBalancaHistoryBlock_transactionRight_balance}>
                                Balance {transaction.balance}
                            </div>
                        </div>
                    </Card>
                )
            }
        </div>
    )
}
