import { useContext, useMemo, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { DownButton } from '../../../../../Components/Predict/actionButton/downButton'
import { UpButton } from '../../../../../Components/Predict/actionButton/upButton'
import { Card } from '../../../../../Components/Predict/card/card'
import { RoundedNumberInput } from '../../../../../Components/Predict/inputs/roundedTextInput/roundedNumberInput'
import PLoader from '../../../../../Components/Predict/loader/Loader'
import GameContext from '../../../../../Context/GameContext'
import { GameProcess } from '../../../../../Domain/Predict/gameProcess'
import { PredictProvider } from '../../../../../Domain/Predict/predictProdiver'
import DownRightArrowLightIcon from '../../../../../Resources/Predict/icons/downRightArrow_light.svg'
import linkIcon from '../../../../../Resources/Predict/icons/link2.svg'
import TopRightArrowLightIcon from '../../../../../Resources/Predict/icons/topRightArrow_light.svg'
import coinIcon from '../../../../../Resources/Predict/images/coin.svg'
import style from './setPositionModalStyle.module.scss'

export enum BetType {
    Down = 0,
    Up = 1,
}

interface SetPositionCardProps {
    betType: BetType;
    gameProcess: GameProcess;
    isOpen: boolean;
    onClose: (inBet: boolean, gameProcessId: number, betType: BetType, betPrice: number) => void;
}

export function SetPositionModal(props: SetPositionCardProps) {
    const { score, token, updateGame } = useContext(GameContext);
    const predictBalance = useMemo(() => Math.round(score / 1_000_000), [score])

    const [betPrice, setBetPrice] = useState<number | null>(null);
    const [isBetPriceNotSelected, setIsBetPriceNotSelected] = useState<boolean>(false);

    const commit = useMemo(() => (betPrice ?? 0) * 0.99, [betPrice])
    const fee = useMemo(() => (betPrice ?? 0) * 0.01, [betPrice]);

    const [isBetSending, setIsBetSending] = useState<boolean>(false);
    const { t } = useTranslation();


    function changeValue(value: number | null) {
        setBetPrice(value);
    }

    async function bet() {
        if (betPrice == null || betPrice <= 0) {
            setIsBetPriceNotSelected(true);
            setTimeout(() => {
                setIsBetPriceNotSelected(false);
            }, 3000);

            return;
        }

        setIsBetSending(true);
        const response = await PredictProvider.bet(props.betType, props.gameProcess.id, betPrice, token!);
        if (response.message == 'ok') {
            updateGame({
                score: score - (betPrice ?? 0) * 1_000_000
            })
            props.onClose(true, props.gameProcess.id, props.betType, betPrice);
        }
        else {
            toast.error("Error :c");
            props.onClose(false, props.gameProcess.id, props.betType, betPrice);

        }

        setIsBetSending(false);
    }

    const canBet = () => {
        if(isBetSending) return false; 
        if((betPrice ?? 0)  > predictBalance) return false;
        if((betPrice ?? 0)  <= 0) return false;

        return true;
    }

    if (isBetSending) return <PLoader />

    return (
        <div  className={style.overlay}>
            <Card className={style.modalContainerCard}>
                <div className={style.modalContainerCard_headerButton}> 
                     <span>{t('Set Position')}</span> 
                     <div onClick={() => props.onClose(false, props.gameProcess.id, props.betType,  (betPrice ?? 0) )}>✕</div>
                </div>
                <div className={style.predictionContainer}>
                    <div className={`${style.predictionContainer__informationBlock} ${style.informationBlock}`}>
                        <div className={style.informationBlock_title}>
                            <div className={style.informationBlock_title_right}>
                                <img src={linkIcon} alt="linkIcon" />
                                <span>#{props.gameProcess.id}</span>
                            </div>
                        </div>
                        <div className={style.informationBlock_circle_badgeUp}>
                            {
                                props.betType == BetType.Up
                                    ?
                                    <>
                                        <span>{t('UP')}</span>
                                        <img src={TopRightArrowLightIcon} alt="TopRightArrow" />
                                    </>
                                    :
                                    <>
                                        <span>{t('DOWN')}</span>
                                        <img src={DownRightArrowLightIcon} alt="DownRightArrowLightIcon" />
                                    </>
                            }
                        </div>
                        <div className={`${style.informationBlock_rightBlock_flex} ${style.rightBlock_flex}`}>
                            <div className={style.rightBlock_flex__element}>
                                <div className={style.rightBlock_flex__sub_element}>
                                    <div className={style.rightBlock_flex__title}>{t('Commit')}</div>
                                    <div className={style.rightBlock_flex__subTitle}>
                                        <span>
                                            <img src={coinIcon} alt="coinIcon" />
                                            {parseFloat(commit.toFixed(2)).toLocaleString('en')}
                                        </span>
                                    </div>
                                </div>
                                <div className={style.rightBlock_flex__sub_element}>
                                    <div className={style.rightBlock_flex__title}>{t('Balance')}</div>
                                    <div className={style.rightBlock_flex__subTitle}>
                                        <span>
                                            <img src={coinIcon} alt="coinIcon" />
                                            {Math.round(predictBalance).toLocaleString('en')}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={style.rightBlock_flex__element}>
                                <div className={style.rightBlock_flex__sub_element}>
                                    <div className={style.rightBlock_flex__title}>{t('Fee')}</div>
                                    <div className={style.rightBlock_flex__subTitle}>
                                        <span>
                                            <img src={coinIcon} alt="coinIcon" />
                                            {parseFloat(fee.toFixed(2)).toLocaleString('en')} (1%)
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={style.rightBlock_flex__element}>
                                <div className={style.rightBlock_flex__sub_element}>
                                    <div className={style.rightBlock_flex__title}>{t('Bet')}</div>
                                </div>
                            </div>

                            <div className={style.rightBlock_flex__element}>
                                <RoundedNumberInput
                                    value={betPrice}
                                    onChange={changeValue}
                                    color='light'
                                    minValue={0}
                                    maxValue={predictBalance}
                                    placeholder={"0"}
                                    autoFocus
                                    style={{
                                        padding: "8px 9px",
                                        fontSize: "34px",
                                        borderRadius: "6px",
                                        textAlign: "center",
                                        border: `${isBetPriceNotSelected ? '1px solid #e84266' : ''}`
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style.predictionContainer_confirmButtonContainer}>
                        {
                            props.betType == BetType.Up
                                ? <UpButton title={t('Confirm')} subTitle={props.gameProcess.coef_up == null ? null : `${(+props.gameProcess.coef_up).toFixed(2)}x`} disabled={!canBet()} onClick={bet} />
                                : <DownButton title={t('Confirm')} subTitle={props.gameProcess.coef_down == null ? null : `${(+props.gameProcess.coef_down).toFixed(2)}x`} disabled={!canBet()} onClick={bet} />
                        }
                    </div>
                    <div className={style.predictionContainer_footer}>
                        {t('You won`t be able to remove or change your position once you enter it.')}
                    </div>
                </div>

                <div><Toaster /></div>
            </Card>
        </div>

    );
}